import {useState, useEffect} from 'react';
import {useTranslation, Trans} from 'react-i18next';
import Loader from '@teladoc/pulse/ui/Loader';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Label from '@teladoc/pulse/ui/Label';
import Radio from '@teladoc/pulse/ui/Radio';
import RadioGroup from '@teladoc/pulse/ui/RadioGroup';
import {getStoredLang} from '@teladoc/pulse/ui/App/g11n';
import RangeSlider from '@teladoc/pulse/ui/RangeSlider';
import Button from '@teladoc/pulse/ui/Button';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import ScreenReader from 'common/screen-reader/ScreenReader';
import Config from 'config';
import {QuestionId} from 'api/protobuf/enrollment/protoTypes';
import UserAPI from 'api/rest/user-api';
import useMessage from 'hook/useMessage';
import {programs} from 'constants/app';
import {
    mixpanelButtonClickedEvent,
    mixpanelPdfViewedEvent,
} from 'utilities/mixpanel-utils';
import {getSsoContext} from 'utilities/utils';
import css from './CoachingPreferences.scss';

const LOWER_GLUCOSE_MIN = 20;
const LOWER_GLUCOSE_MAX = 69;
const UPPER_GLUCOSE_MIN = 181;
const UPPER_GLUCOSE_MAX = 600;
const {
    memberSupportPhoneNumber,
    memberSupportTextNumber,
    memberSupportFormattedPhoneNumber,
    memberSupportFormattedTextNumber,
    oneAppMemberSupportPhoneNumber,
    oneAppMemberSupportFormattedPhoneNumber,
    privacyPracticeUrl,
    tosUrl,
} = Config;

const {DIABETES} = programs;

const CoachingPreferences = ({
    prefMethDefault,
    sectionInfo,
    lowerGlucoseLevelDefault = 50,
    upperGlucoseLevelDefault = 400,
    close,
    selectedPrograms,
    isOneApp,
    exitCoachingPreferences,
}) => {
    const {t, ready} = useTranslation('content', {
        useSuspense: false,
    });
    const locale = getStoredLang().toLowerCase();
    const {showQuestionsFetchErrorMessage} = useMessage();
    const [prefContact, setPrefContact] = useState(prefMethDefault);
    const [lowerGlucoseLevel, setLowerGlucoseLevel] = useState(
        lowerGlucoseLevelDefault
    );
    const [upperGlucoseLevel, setUpperGlucoseLevel] = useState(
        upperGlucoseLevelDefault
    );
    const [isDiabetesIncluded, setIsDiabetesIncluded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const isImpersonation = getSsoContext()?.isImpersonation ?? false;

    useEffect(() => {
        if (isOneApp) {
            const answersWithinContext = sectionInfo.context?.answersList || [];

            const programAnswer = answersWithinContext.find(
                answer => answer.questionId?.id === QuestionId.Values.PROGRAM
            );

            const oneAppSelectedPrograms =
                programAnswer?.value?.multiSelectEnum?.enumCodeList || [];

            setIsDiabetesIncluded(oneAppSelectedPrograms.includes(DIABETES));

            const coachingStep = async () => {
                const bgLimit = await UserAPI.getCoachingPrefs();
                const {BGLowerLimit, BGUpperLimit} = bgLimit;

                setLowerGlucoseLevel(parseInt(BGLowerLimit, 10));
                setUpperGlucoseLevel(parseInt(BGUpperLimit, 10));
                setLoading(false);
            };

            coachingStep().catch(() => showQuestionsFetchErrorMessage());
        } else {
            setIsDiabetesIncluded(selectedPrograms.includes(DIABETES));
        }
        // eslint-disable-next-line
    }, []);

    const onChangeLowerGlucose = value => {
        setLowerGlucoseLevel(value[0]);
    };

    const onChangeUpperGlucose = value => {
        setUpperGlucoseLevel(value[0]);
    };

    const onChangeContactBy = evt => {
        const contactVal = evt.target.value;

        setShowErrorMessage(false);
        setPrefContact(contactVal);
    };

    // Alert Communication from Coach
    const prefMeth = {
        appInstId: '1',
        prefName: 'prefMeth',
        appId: 5,
        prefVal: prefContact === '' ? 'UNSELECTED' : prefContact, // OPT: SMS, PHONE, UNSELECTED
    };

    // BGLowerLimit
    const BGLowerLimit = {
        appInstId: '1',
        prefName: 'BGLowerLimit',
        appId: 11,
        prefVal: lowerGlucoseLevel,
    };

    // BGUpperLimit
    const BGUpperLimit = {
        appInstId: '1',
        prefName: 'BGUpperLimit',
        appId: 11,
        prefVal: upperGlucoseLevel,
    };

    const onTermsOfServiceClick = () => {
        mixpanelPdfViewedEvent('Terms of Service');
    };

    const onPrivacyPracticesClick = () => {
        mixpanelPdfViewedEvent('Privacy Policy');
    };

    const handleConfirmationPrefs = () => {
        mixpanelButtonClickedEvent(
            '',
            'Confirmation',
            t('Confirmation.prefs.btnText'),
            'CTA'
        );
        const confirmationData = isDiabetesIncluded
            ? [prefMeth, BGLowerLimit, BGUpperLimit]
            : [prefMeth];

        if (!prefContact) {
            setShowErrorMessage(true);
        } else {
            setShowErrorMessage(false);
            UserAPI.updateCoachingPrefs(confirmationData)
                .then(() => {
                    // oneApp Condition
                    if (isOneApp) {
                        exitCoachingPreferences();
                    } else {
                        close();
                    }
                })
                .catch(error => {
                    showQuestionsFetchErrorMessage();
                });
        }
    };

    const generateAriaValueText = (state, minLabelKey, maxLabelKey) => {
        const {value} = state;
        const valueNow = value;

        return `${t('Confirmation.prefs.range')} ${t(minLabelKey)} ${t(
            'Confirmation.prefs.unitsText'
        )}  ${t('Confirmation.prefs.to')} ${t(maxLabelKey)} ${t(
            'Confirmation.prefs.unitsText'
        )} ${t('Confirmation.prefs.value')}: ${valueNow} ${t(
            'Confirmation.prefs.unitsText'
        )}`;
    };

    const lowerGlucoseAriaValueText = state => {
        return generateAriaValueText(
            state,
            'Confirmation.prefs.lowerMinLabel',
            'Confirmation.prefs.lowerMaxLabel'
        );
    };

    const upperGlucoseAriaValueText = state => {
        return generateAriaValueText(
            state,
            'Confirmation.prefs.upperMinLabel',
            'Confirmation.prefs.upperMaxLabel'
        );
    };

    return (
        <>
            {loading && <Loader className="Loader-module-root" />}
            {!loading && (
                <div
                    className={cn(css.coachingPrefsContainer, {
                        [css.oneAppCoachingPrefsContainer]: isOneApp,
                    })}
                >
                    {ready && (
                        <div
                            className={cn(css.coachItem, {
                                [css.oneAppCoachItem]: isOneApp,
                            })}
                        >
                            <h2 className={css.coachTitle}>
                                {t('Confirmation.oneAppPrefs.title')}
                            </h2>

                            {isImpersonation ? (
                                <>
                                    <div className={css.i}>
                                        “Would you prefer a text, call, or no
                                        contact from our expert coaches to help
                                        you turn things around if your device
                                        readings are out of range? You can
                                        manage your sugar levels and contact
                                        preferences in your account at any
                                        time!“
                                        <br />
                                    </div>
                                    <div>
                                        [It is not necessary to adjust or
                                        discuss the default sugar levels unless
                                        the member has questions.]
                                    </div>
                                </>
                            ) : (
                                <p
                                    className={cn(css.prefsMsg, {
                                        [css.oneAppPrefsMsg]: isOneApp,
                                    })}
                                >
                                    {t('Confirmation.oneAppPrefs.description')}
                                </p>
                            )}

                            {isOneApp && (
                                <div className={css.oneAppSeparator} />
                            )}
                            {isDiabetesIncluded && (
                                <section>
                                    <h3
                                        className={cn({
                                            [css.oneAppSubHeader]: isOneApp,
                                        })}
                                    >
                                        {t('Confirmation.prefs.lowerSubheader')}
                                    </h3>
                                    <div className={css.glucoseDetails}>
                                        <RangeSlider
                                            classNameRoot={css.glucoseSlider}
                                            onChange={onChangeLowerGlucose}
                                            defaultValues={[lowerGlucoseLevel]}
                                            renderCurrentValue={value =>
                                                `${value[0]} mg/dL`
                                            }
                                            i18nAriaValueText={
                                                lowerGlucoseAriaValueText
                                            }
                                            i18nHandleLabels={t(
                                                'Confirmation.prefs.lowerLabel'
                                            )}
                                            min={LOWER_GLUCOSE_MIN}
                                            max={LOWER_GLUCOSE_MAX}
                                            showPercentCharacter={false}
                                            i18nMinLabel={`${t(
                                                'Confirmation.prefs.lowerMinLabel'
                                            )} ${t('Confirmation.prefs.unit')}`}
                                            i18nMaxLabel={`${t(
                                                'Confirmation.prefs.lowerMaxLabel'
                                            )} ${t('Confirmation.prefs.unit')}`}
                                            displayUnits="mg/dL"
                                        />
                                    </div>
                                    <h3
                                        className={cn({
                                            [css.oneAppSubHeader]: isOneApp,
                                        })}
                                    >
                                        {t('Confirmation.prefs.upperSubheader')}
                                    </h3>
                                    <div className={css.glucoseDetails}>
                                        <RangeSlider
                                            classNameRoot={css.glucoseSlider}
                                            onChange={onChangeUpperGlucose}
                                            defaultValues={[upperGlucoseLevel]}
                                            renderCurrentValue={value =>
                                                `${value[0]} mg/dL`
                                            }
                                            i18nAriaValueText={
                                                upperGlucoseAriaValueText
                                            }
                                            i18nHandleLabels={t(
                                                'Confirmation.prefs.upperLabel'
                                            )}
                                            min={UPPER_GLUCOSE_MIN}
                                            max={UPPER_GLUCOSE_MAX}
                                            showPercentCharacter={false}
                                            i18nMinLabel={`${t(
                                                'Confirmation.prefs.upperMinLabel'
                                            )} ${t(
                                                'Confirmation.prefs.unit'
                                            )} `}
                                            i18nMaxLabel={`${t(
                                                'Confirmation.prefs.upperMaxLabel'
                                            )}  ${t(
                                                'Confirmation.prefs.unit'
                                            )}`}
                                            displayUnits="mg/dL"
                                        />
                                    </div>
                                </section>
                            )}
                            {isOneApp && (
                                <div className={css.oneAppSeparator} />
                            )}

                            <section>
                                <RadioGroup
                                    id="radio"
                                    name="radio"
                                    onChange={onChangeContactBy}
                                    label={
                                        <Label>
                                            <h3
                                                className={cn(
                                                    css.contactHeader,
                                                    {
                                                        [css.oneAppSubHeader]:
                                                            isOneApp,
                                                    }
                                                )}
                                            >
                                                {isOneApp
                                                    ? t(
                                                          'Confirmation.oneAppPrefs.subHeader'
                                                      )
                                                    : t(
                                                          'Confirmation.prefs.subHeader'
                                                      )}
                                            </h3>
                                        </Label>
                                    }
                                >
                                    <Radio
                                        key="PHONE"
                                        id="PHONE"
                                        value="PHONE"
                                        classNameSelector={
                                            showErrorMessage
                                                ? css.customError
                                                : ''
                                        }
                                        defaultChecked={
                                            prefMethDefault === 'PHONE'
                                        }
                                        label={
                                            <Label>
                                                {t(
                                                    'Confirmation.prefs.radioPhone'
                                                )}
                                            </Label>
                                        }
                                    />
                                    <Radio
                                        key="SMS"
                                        id="SMS"
                                        value="SMS"
                                        defaultChecked={
                                            prefMethDefault === 'SMS'
                                        }
                                        classNameSelector={
                                            showErrorMessage
                                                ? css.customError
                                                : ''
                                        }
                                        label={
                                            <Label>
                                                {t(
                                                    'Confirmation.prefs.radioSMS'
                                                )}
                                            </Label>
                                        }
                                    />
                                    <Radio
                                        key="UNSELECTED"
                                        id="UNSELECTED"
                                        value="UNSELECTED"
                                        defaultChecked={
                                            prefMethDefault === 'UNSELECTED'
                                        }
                                        classNameSelector={
                                            showErrorMessage
                                                ? css.customError
                                                : ''
                                        }
                                        label={
                                            <Label>
                                                {t(
                                                    'Confirmation.oneAppPrefs.oneAppRadioUnselected'
                                                )}
                                            </Label>
                                        }
                                    />
                                </RadioGroup>
                                {showErrorMessage && (
                                    <FormElementError>
                                        {t('Confirmation.oneAppPrefs.error')}
                                    </FormElementError>
                                )}

                                {!isOneApp && prefContact === 'SMS' && (
                                    <p className={css.displayLegal}>
                                        <Trans
                                            i18nKey="content:Confirmation.prefs.legalDisclosure"
                                            values={{
                                                memberSupportFormattedTextNumber,
                                                memberSupportFormattedPhoneNumber,
                                            }}
                                            components={[
                                                /* eslint-disable react/jsx-key */
                                                <a
                                                    href={`tel:${memberSupportPhoneNumber}`}
                                                >
                                                    {memberSupportPhoneNumber}
                                                </a>,
                                                <a
                                                    href={`tel:${memberSupportTextNumber}`}
                                                >
                                                    {memberSupportTextNumber}
                                                </a>,
                                                /* eslint-disable react/jsx-key */
                                            ]}
                                        />
                                    </p>
                                )}
                                {isOneApp && prefContact === 'SMS' && (
                                    <p className={css.displayLegal}>
                                        <Trans
                                            i18nKey="content:Confirmation.oneAppPrefs.oneAppLegalDisclosure"
                                            values={{
                                                memberSupportFormattedTextNumber,
                                                oneAppMemberSupportFormattedPhoneNumber,
                                            }}
                                            components={[
                                                /* eslint-disable react/jsx-key */
                                                <a
                                                    href={`tel:${oneAppMemberSupportPhoneNumber}`}
                                                >
                                                    {
                                                        oneAppMemberSupportPhoneNumber
                                                    }
                                                </a>,
                                                <a
                                                    href={`tel:${memberSupportTextNumber}`}
                                                >
                                                    {memberSupportTextNumber}
                                                </a>,
                                                /* eslint-disable react/jsx-key */
                                            ]}
                                        />
                                    </p>
                                )}
                            </section>
                            {isOneApp && (
                                <div className={css.oneAppLegalTextBlock}>
                                    <Trans
                                        i18nKey="content:Confirmation.oneAppPrefs.footerText"
                                        values={{tosUrl, privacyPracticeUrl}}
                                        components={[
                                            /* eslint-disable react/jsx-key */
                                            <a
                                                href={`${tosUrl}?locale=${locale}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                onClick={onTermsOfServiceClick}
                                                className={css.linkFont}
                                            >
                                                <ScreenReader />
                                            </a>,
                                            <a
                                                href={`${privacyPracticeUrl}?locale=${locale}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                onClick={
                                                    onPrivacyPracticesClick
                                                }
                                                className={css.linkFont}
                                            >
                                                <ScreenReader />
                                            </a>,
                                            /* eslint-disable react/jsx-key */
                                        ]}
                                    />
                                </div>
                            )}
                            {!isOneApp && (
                                <Button
                                    className={css.coachingBtn}
                                    variant="primary"
                                    onClick={handleConfirmationPrefs}
                                >
                                    {t('Confirmation.prefs.btnText')}
                                </Button>
                            )}
                            {isOneApp && (
                                <Button
                                    className={css.coachingNextBtn}
                                    variant="primary"
                                    onClick={handleConfirmationPrefs}
                                >
                                    {t('Confirmation.oneAppPrefs.btnText')}
                                </Button>
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

CoachingPreferences.propTypes = {
    close: PropTypes.func,
    prefMethDefault: PropTypes.string,
    lowerGlucoseLevelDefault: PropTypes.number,
    upperGlucoseLevelDefault: PropTypes.number,
    selectedPrograms: PropTypes.array,
    isOneApp: PropTypes.bool,
    sectionInfo: PropTypes.object,
    exitCoachingPreferences: PropTypes.func,
};

export default CoachingPreferences;
