import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';
import {getCountryCallingCode} from 'react-phone-number-input';
import ArrowLeftDefault from '@teladoc/pulse/icons/arrow-left-default.svg';
import Form from '@teladoc/pulse/ui/Form';
import Loader from '@teladoc/pulse/ui/Loader';
import notify from '@teladoc/pulse/ui/Notification/index';
import PIWIKUtils from 'utilities/piwik-utils';
import {QuestionId, ValidationError} from 'api/protobuf/enrollment/protoTypes';
import {
    createAccount,
    getQuestionNameById,
    navigateBack,
    navigateHere,
    navigateNext,
    submitAnswers,
} from 'api/protobuf/enrollment/services';
import UserAPI from 'api/rest/user-api';
import Config from 'config';
import notificationConfig from 'constants/notification';
import messageConfig from 'constants/messages';
import {errorTypes, IDK} from 'constants/type';
import {questionConfig, questionFieldTypes} from 'constants/questions';
import {contentIds} from 'constants/contentIds';
import {COUNTRY_CODE} from 'constants/countryCode';
import useNavigation from 'hook/useNavigation';
import useMessage from 'hook/useMessage';
import {
    mixpanelAccountCreated,
    mixpanelButtonClicked,
    mixpanelGeneralError,
    mixpanelPageLand,
    mixpanelPagePass,
    mixpanelSessionTimeOut,
    mixpanelUnableToContinue,
    mixpanelProgramSelectionClick,
} from 'utilities/mixpanel-utils';
import {
    amplitudeFlag,
    AmplitudeUtils,
    getDisplayTitleFromSection,
} from 'utilities/amplitude-utils';
import {
    devConsoleLog,
    generateResponseForSsoWarmWelcome,
    getFirstNonEmptyKey,
    getRegistrationContext,
    getSsoContext,
    getRegistrationStepIndex,
    getRegistrationStepLoader,
    getRegistrationStepUrls,
    getRestApiAccessCode,
    setIsVisited,
    setRegCode,
    setRegistrationStepLoader,
    setRegistrationStepUrls,
    setRestApiAccessCode,
    setTitleFromGRPC,
    setDisplayTitle,
    setUserEmail,
    setIsEmailQuestionAsked,
    setCallBackUrl,
    getObjectKeyByValue,
    removeSpecificQuestionsIfNotInForm,
    cleanSessionInfo,
    getCallBackUrl,
    getStepNumber,
    getUserProgress,
    setUserProgress,
} from 'utilities/utils';
import {checkIsGivenQuestion} from 'components/question/questions-utils';
import ProgressBar from 'components/progressBar';
import CoachingPreferences from 'components/content/subComponents/CoachingPreferences';
import NavigationButton from 'components/navigationButton';
import Section from 'components/section';
import Info from 'components/info';
import U13Experience from 'components/u13Experience';
import css from './Registration.scss';

const {
    EMAIL_IN_USE,
    UNDERFLOW,
    ILLEGAL_SCALAR_VALUE,
    INVALID_REG_CODE,
    INVALID_PHONE_NUMBER,
    INVALID_ZIP_CODE,
} = errorTypes;

const {
    TEXT_FIELD,
    SINGLE_SELECT_ENUM,
    MULTI_SELECT_ENUM,
    BOOLEAN_FIELD,
    INTEGER_FIELD,
    FLOAT_FIELD,
    DATE_FIELD,
    COMPOUND_FIELD,
    UNION_FIELD,
    PASSWORD_TEXT_FIELD,
} = questionFieldTypes;
const {ERROR_CREATE_ACCOUNT} = messageConfig;
const {SsoWarmWelcome, OneAppIntroduction, Preferences} = contentIds;
const {consentSection, sectionsDefault: sections} = Config;

// TODO: Move out for better organized.
/**
 * Get data for previous step.
 * This function is for previous button.
 * Pace is one step but need to continuously check until not beginning of section.
 * @param {number} sectionIndex - Index of section.
 * @param {string} sectionPathname - Pathname of section.
 * @returns {Promise<unknown>} - Promise.
 */
const getDataForPreviousStep = async (sectionIndex, sectionPathname) => {
    let navigateResponse;
    let sectionIndexForNavigation = sectionIndex;
    let sectionsForNavigation = [...sections];

    if (sectionPathname === consentSection.pathname) {
        const [signup, ...rest] = sections;

        sectionIndexForNavigation = 1;
        sectionsForNavigation = [signup, consentSection, ...rest];
    }

    /*
     * Loop under the configured sections size
     *  to cover some corner case which run into
     *  beginning of section right after another beginning of section.
     */
    for (
        let i = sectionIndexForNavigation, j = sectionIndexForNavigation;
        i > -1;
        i--
    ) {
        // eslint-disable-next-line
        navigateResponse = await navigateBack(sectionsForNavigation[j].id)
            // Comment out to take mock data.
            // TODO: Uncomment back when BE is ready.
            // .then(response => response.toObject())
            .then(response => response)
            .catch(
                error =>
                    // TODO: Test error.
                    error
            );

        if (navigateResponse.beginningOfSectionReached) {
            // Move to previous section.
            j--;
        } else {
            break;
        }
    }

    // Return the final response get from gRPC.
    return navigateResponse;
};

// TODO: Move out for better organized.
/**
 * Get data for next step.
 * This function is for next button.
 * Pace is one step but need to continuously check until not end of section.
 * @param {number} sectionIndex - Index of section.
 * @param {string} sectionPathname - Pathname of section.
 * @returns {Promise<unknown>} - Promise.
 */
const getDataForNextStep = async (sectionIndex, sectionPathname) => {
    let navigateResponse;
    let sectionIndexForNavigation = sectionIndex;
    const sectionsForNavigation = [...sections];

    if (sectionPathname === consentSection.pathname) {
        sectionIndexForNavigation = 0;
        sectionsForNavigation.unshift(consentSection);
    }
    /*
     * Loop under the configured sections size
     *  to cover some corner case which run into
     *  end of section right after another end of section.
     */
    for (
        let i = sectionIndexForNavigation, j = sectionIndexForNavigation;
        i < sectionsForNavigation.length;
        i++
    ) {
        // eslint-disable-next-line
        navigateResponse = await navigateNext(sectionsForNavigation[j].id)
            .then(response => response)
            .catch(
                error =>
                    // TODO: Test error.
                    error
            );

        if (navigateResponse.endOfSectionReached) {
            // Move to next section.
            j++;
        } else {
            break;
        }
    }

    // Return the final response get from gRPC.
    return navigateResponse;
};

/**
 * Convert formdata into a hashmap with the question id as key and answer as value
 * For compound questions, the key is a hash of sub question ids and sub question answers
 * @param {Object} formData - Hashmap of question ids to answers.
 * @returns {Object} - hashmap with the question id as key and answer as value
 */
const parseAnswersFromForm = (formData = {}) => {
    return Object.keys(formData).reduce((hash, key) => {
        const [, questionId, subQuestionId, grandQuestionId] = key.split('_-_'); // ${QUESTION_NAME}_-_${QUESTION_ID}_-_${SUB_QUESTION_ID_INT}
        const value = formData[key];
        const countryCode = COUNTRY_CODE[formData?.countryCode];

        // incorrect format; questionId start at one and can't be zero
        // also to protect again incorrect data from formData: { countryCode: US }
        if (!questionId) {
            devConsoleLog(
                `Skipping form data because of no question ID: ${key}`
            );

            return hash;
        }

        if (!grandQuestionId) {
            if (
                !subQuestionId &&
                questionId !== `${QuestionId.Values.MOBILE_PHONE_INTERNATIONAL}`
            ) {
                hash[questionId] = value;
            } else {
                // compound question
                if (!hash[questionId]) {
                    hash[questionId] = {};
                }

                // Special handle for internation phone since it return as compound question.
                if (
                    questionId ===
                    `${QuestionId.Values.MOBILE_PHONE_INTERNATIONAL}`
                ) {
                    hash[questionId][QuestionId.Values.MOBILE_PHONE_COUNTRY] =
                        countryCode;

                    // for international address, we will append the country calling code prefix for it
                    hash[questionId][
                        QuestionId.Values.PHONE_MOBILE
                    ] = `+${getCountryCallingCode(
                        formData?.countryCode
                    )} ${value}`;
                } else {
                    hash[questionId][subQuestionId] = value;
                }
            }
        } else {
            // union question
            if (!hash[questionId]) {
                hash[questionId] = {};
            }

            if (!hash[questionId][subQuestionId]) {
                hash[questionId][subQuestionId] = {};
            }

            hash[questionId][subQuestionId][grandQuestionId] = value;
        }

        return hash;
    }, {});
};

/**
 * convert answer hash and question array into Answer format for BE
 * @param {Object} answerHash - Hashmap of question ids to answers.
 * @param {Object[]} questions - Index of section.
 * @returns {Object[]} - Array of Answers.
 */
const parseAnswers = (answerHash = {}, questions = []) => {
    const answers = [];

    questions.forEach(question => {
        const questionId = question.questionId.id;
        const value = answerHash[questionId];
        const dataType = getFirstNonEmptyKey(question.dataType);

        // missing typedValueType, badly formatted data
        if (!question.dataType || Object.keys(question.dataType).length === 0) {
            throw new Error(
                `dataType not found in Question object for: ${questionId}`
            );
        }

        // optional question and not returning this answer to the backend
        if (!question.required && (value === IDK || !value)) {
            devConsoleLog(
                `Skipping answer for ${questionId} because it is optional and the user provided no answer`
            );

            return;
        }

        if (dataType === UNION_FIELD) {
            const subAnswerHash = value;
            const subQuestions = question.dataType[
                dataType
            ].questionsList.filter(
                q => `${q.questionId.id}` === Object.keys(subAnswerHash)[0]
            );

            const subValues = parseAnswers(subAnswerHash, subQuestions);

            answers.push({
                questionId: question.questionId,
                type: dataType,
                value: subValues[0],
            });
        } else if (dataType === COMPOUND_FIELD) {
            let subAnswerHash = value;

            // Only RaceV2 will enter this "else if" because RaceV2 is COMPOUND_FIELD
            // Special handle for RaceV2 question
            // when entered value to RACE_OTHER textInput and selected "Prefer not to share, delete all other values in form
            if (
                subAnswerHash &&
                subAnswerHash[QuestionId.Values.RACE_SELECTION] === 'DK'
            ) {
                subAnswerHash = {
                    [QuestionId.Values.RACE_SELECTION]: 'DK',
                };
            }

            const subQuestions = question.dataType[dataType].questionsList;
            const subValues = parseAnswers(subAnswerHash, subQuestions);

            answers.push({
                questionId: question.questionId,
                type: dataType,
                subValues,
            });
        } else {
            // Handle special case for under 13.
            if (!value && questionId === QuestionId.Values.LAST_NAME) {
                return;
            }

            // handle max char error for address line1
            if (questionId === QuestionId.Values.ADDRESS_LINE1) {
                if (value.length > 35) {
                    const error = new Error('address error');

                    error.code = 'address error';
                    throw error;
                }
            }

            let answerValue = value;

            if (dataType === MULTI_SELECT_ENUM) {
                answerValue = value ? [].concat(value) : [];
            }

            answers.push({
                questionId: question.questionId,
                type: dataType,
                value: answerValue,
            });
        }
    });

    return answers;
};

export const createAndAuthenticateAccount = async () => {
    try {
        let authCode = getRestApiAccessCode();

        if (authCode) {
            /*
             * If the auth code has already existed,
             *  it means we created account successfully earlier.
             */

            return;
        }
        const createAccountResponse = await createAccount()
            .then(response => response.toObject())
            .catch(error => {
                mixpanelGeneralError({
                    name: 'createAccount',
                    location: 'Registration.createAccount.catchOfPromise',
                    additionalInfo: error,
                });

                return ERROR_CREATE_ACCOUNT.id;
            });

        const {
            accountCreated,
            configurationChangeDetected,
            brokenConfigurationDetected,
            unableToContinue,
            badRequest,
            redirectUrl,
        } = createAccountResponse;

        if (redirectUrl) {
            setCallBackUrl(redirectUrl.value);
        }

        authCode = accountCreated?.authCode;

        if (authCode) {
            // Log mixpanel event from FE.
            mixpanelAccountCreated();
            try {
                PIWIKUtils.trackAccountCreated();
            } catch (error) {
                mixpanelGeneralError({
                    name: 'Piwik Tracking Error',
                    location: 'Registartion.async.createAccount',
                    additionalInfo: error,
                });
            }

            // Set rest API and store it to localstorage or cookie.
            setRestApiAccessCode(authCode);

            /*
             * Get access token and refresh token from auth code.
             * Set header with a access token for sub sequence rest API call.
             * Set refresh token for member portal redirection.
             */
            await UserAPI.loginWithAuthCode(authCode);
        } else {
            if (
                configurationChangeDetected ||
                brokenConfigurationDetected ||
                unableToContinue ||
                badRequest
            ) {
                // TODO: Dispatch different message.
            }

            return ERROR_CREATE_ACCOUNT.id;
        }
    } catch (error) {
        mixpanelGeneralError({
            name: 'createAccount',
            location: 'Registration.createAccount.catchOfTry',
            additionalInfo: error,
        });

        return ERROR_CREATE_ACCOUNT.id;
    }
};

/**
 * convert answer hash and question array into Answer format for BE
 * @param {{}} registrationStep - RegistrationStep from navigate gRPC responses, either currentStep,
 *     previousStep, or NextStep
 * @param {String} QuestionName - Question Name
 * @returns {Boolean} - is it the email question or not in the registrationStep
 */
const isSpecificQuestion = (registrationStep, QuestionName) => {
    if (registrationStep?.questionsToAsk?.questionsList) {
        return Boolean(
            registrationStep.questionsToAsk.questionsList.find(
                section =>
                    getQuestionNameById(section.questionId.id) === QuestionName
            )
        );
    }

    return false;
};

const Registration = ({history, location}) => {
    const {t} = useTranslation(['questions', 'common', 'notification']);
    const {
        showBadRequestErrorMessage,
        showAccountCreateErrorMessage,
        showAccountAlreadyExistsMessage,
        showClientCappedMessage,
        showTimeoutMessage,
        showUnderAgeMessage,
        showNotFoundRegcodeMessage,
        showU13DisqualifiedMemberSupportErrorMessage,
        showInternationalNumberNotAllowedErrorMessage,
        showZipcodeNotSupported,
    } = useMessage();

    const formRef = useRef();
    // State to flag loading or not.
    const [isLoading, setIsLoading] = useState(true);
    // State to hold the whole return from gRPC.
    const userProgress = getUserProgress();
    const [currentRegistrationStep, setCurrentRegistrationStep] = useState(
        userProgress?.currentStep || {}
    );
    // State to show errors for each questions from submit answers and navigate gRPC responses
    const [questionErrors, setQuestionErrors] = useState([]);
    // Get step URLs from registration history.
    const stepUrls = getRegistrationStepUrls();
    // Get previous step index from registration history.
    const previousStepIndex = getRegistrationStepIndex();
    // Get current step index from URL.
    const currentStepIndex = stepUrls.findIndex(
        url => url.indexOf(location.pathname) === 0
    );
    // Get current section pathname from URL.
    const [, , currentSectionPathname] = location.pathname.split('/');
    // Get current section index based on the pathname.
    let currentSectionIndex = sections.findIndex(
        s => s.pathname === currentSectionPathname
    );
    // state to store the navigationNextLabel
    const [navigationNextLabel, setNavigationNextLabel] = useState(
        t('buttonsLabel.next')
    );
    // A1C Submit Button logic
    const sectionInfo = currentRegistrationStep;
    const a1cSubmitButton =
        sectionInfo?.questionsToAsk?.nodeTitleId ===
        'askNonMemberA1cShippingAddr';

    // state to flag navigateBackPossible
    const [isBackButtonDisabled, setIsBackButtonDisabled] = useState(false);
    // hide side info bar for salesforce
    const {client} = getRegistrationContext() ?? {};
    const isSalesforce = client === Config.client.salesforce;
    const isOneApp = client === Config.client.oneapp;
    const isImpersonation = getSsoContext()?.isImpersonation ?? false;
    const [isOneAppIntroductionPage, setIsOneAppIntroductionPage] =
        useState(false);

    // check to see if currentSectionPathname in sections array is found and in the stepUrls
    // otherwise set it to 0
    const pathNameFoundInStepUrls = stepUrls.some(
        url => url.indexOf(currentSectionPathname) !== -1
    );

    if (currentSectionIndex === -1 || !pathNameFoundInStepUrls) {
        currentSectionIndex = 0;
    }

    const disableNavBtn = useSelector(state => state.navigationBtnDisable);
    const dispatch = useDispatch();

    const setNavigationLabel = label => {
        const {nextLabel} = label;

        if (nextLabel) {
            setNavigationNextLabel(t(nextLabel));
        }
    };

    const handleIdle = () => {
        mixpanelSessionTimeOut(currentRegistrationStep);

        if (amplitudeFlag) {
            AmplitudeUtils.trackSessionTimeOut(currentRegistrationStep);
        }
        showTimeoutMessage();
    };

    /**
     * Call BE API to submit answers.
     * Construct URL and redirect to previous page if good to go back.
     * Page URL will include dynamically generated step id.
     * @returns {void}
     */
    const gotoPreviousStep = async () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        // Mixpanel navigation back click event track.
        // mixpanelNavigateBackClick(currentRegistrationStep);
        mixpanelButtonClicked(
            currentRegistrationStep,
            t('buttonsLabel.back'),
            'navigationBack'
        );

        // TODO: Display message if value of forms changed.
        // Show spinning to give visual effect.
        setIsLoading(true);
        // Set flag to signal the step is loaded by app action, not browser action.
        setRegistrationStepLoader(true);

        let navigateBackResponse;

        try {
            navigateBackResponse = await getDataForPreviousStep(
                currentSectionIndex,
                currentSectionPathname
            );
        } catch (error) {
            setIsLoading(false);
            setRegistrationStepLoader(false);
            mixpanelGeneralError({
                name: 'getDataForPreviousStep',
                location:
                    'Registration.gotoPreviousStep.getDataForPreviousStep',
                additionalInfo: error,
            });
            showBadRequestErrorMessage();

            return;
        }

        const {
            // unableToGoBack,
            // beginningOfSectionReached,
            previousStep,
            navigateBackPossible,
            redirectUrl,
            // badRequest,
            title,
        } = navigateBackResponse;

        if (redirectUrl) {
            setCallBackUrl(redirectUrl.value);
        }

        if (title) {
            setTitleFromGRPC(title);

            if (previousStep) {
                const displayTitle = getDisplayTitleFromSection(
                    previousStep,
                    t
                );

                setDisplayTitle(displayTitle);
            }
        }

        const isCongratsOnBehaviorHealthPage =
            previousStep?.contentId === 'CongratsOnBehaviorHealth';

        if (isCongratsOnBehaviorHealthPage) {
            setIsBackButtonDisabled(false);
        } else {
            setIsBackButtonDisabled(navigateBackPossible);
        }

        setIsBackButtonDisabled(navigateBackPossible);

        if (previousStep) {
            if (previousStep.contentId === SsoWarmWelcome) {
                navigateBackResponse =
                    generateResponseForSsoWarmWelcome(navigateBackResponse);
            }

            if (previousStep.contentId === OneAppIntroduction) {
                setIsOneAppIntroductionPage(true);
            } else {
                setIsOneAppIntroductionPage(false);
            }

            const {sectionId, stepNumber, numberOfSteps} =
                previousStep.context || {};
            let previousSection = {};

            if (sectionId === consentSection.id) {
                previousSection = consentSection;
            } else {
                previousSection = sections.find(
                    section => section.id === sectionId
                );
            }

            if (previousSection) {
                // It is a valid section and construct the URL for previous step.
                const previousStepUrl = `${Config.registerStartUrl}/${previousSection.pathname}/${numberOfSteps}/${stepNumber}`;

                if (isSpecificQuestion(previousStep, 'EMAIL')) {
                    setIsEmailQuestionAsked(false);
                }

                if (currentStepIndex === 0) {
                    /*
                     * Handle login back in user case.
                     * Add the new step to the beginning.
                     */
                    stepUrls.unshift(previousStepUrl);
                } else if (previousStepUrl !== stepUrls[currentStepIndex - 1]) {
                    /*
                     * Step URL is different than the one in the registration history.
                     * Replace old one since it is not valid anymore.
                     */
                    stepUrls.splice(currentStepIndex - 1, 1, previousStepUrl);
                }

                // Update registration history to local storage or cookie.
                setRegistrationStepUrls(stepUrls);
                // Do the redirection.
                history.push(previousStepUrl);
                // Update current Registration Step
                setCurrentRegistrationStep(previousStep);
            } else {
                // TODO: Display proper message.
            }
        } else {
            // TODO: Display proper message.
        }
        setIsLoading(false);
        setRegistrationStepLoader(false);

        // Mixpanel page pass event track.
        mixpanelPagePass(currentRegistrationStep);

        if (amplitudeFlag) {
            AmplitudeUtils.trackPagePass(currentRegistrationStep);
        }
    };

    /**
     * Call BE API to submit answers.
     * Construct URL and redirect to next page if good to go next.
     * Page URL will include dynamically generated step id.
     * @returns {void}
     */
    const gotoNextStep = async () => {
        setUserProgress(null);

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        // Mixpanel navigation next click event track.
        // mixpanelNavigateNextClick(currentRegistrationStep);

        mixpanelButtonClicked(
            currentRegistrationStep,
            navigationNextLabel,
            'navigationNext'
        );

        let accountCreated = false;
        const form = formRef.current;

        if (!form.isValid()) {
            form.animateToInvalid();

            return;
        }

        // Setting Toast for A1c Submit Button

        if (a1cSubmitButton) {
            const {title, message} = notificationConfig.SUCCESS_SHIPPING;

            notify({
                type: 'success',
                title: t(title, {ns: 'notification'}),
                message: t(message, {ns: 'notification'}),
            });
        }
        // Show spinning to give visual effect.
        setIsLoading(true);
        // Set flag to signal the step is loaded by app action, not browser action.
        setRegistrationStepLoader(true);

        // If there are any questions, then get the formData and send to API
        if (currentRegistrationStep.questionsToAsk?.questionsList) {
            // Get data from form and parse the results with questions into Answers
            const {...formData} = form.submit();

            // here is the fix for GROW-2825
            // we only need to filter out the empty TERMS_AND_CONDITIONS question: questionID - 86
            const questions = removeSpecificQuestionsIfNotInForm(
                formData,
                currentRegistrationStep.questionsToAsk.questionsList,
                currentRegistrationStep.questionsToAsk.nodeTitleId,
                QuestionId.Values.TERMS_AND_CONDITIONS,
                QuestionId.Values.SUPPORTED_REGION,
                QuestionId.Values.ZIP
            );

            //
            const isDBTMedQuestion = isSpecificQuestion(
                currentRegistrationStep,
                'DIABETES_MEDS'
            );

            // if DBTMed Question and user did not check anything, add empty array to the formData
            if (isDBTMedQuestion && isEmpty(formData)) {
                formData['DIABETES_MEDS_-_28'] = [];
            }

            let answerHash;
            let answers;

            try {
                answerHash = parseAnswersFromForm(formData);
                answers = parseAnswers(answerHash, questions);
            } catch (err) {
                setIsLoading(false);
                setRegistrationStepLoader(false);
                mixpanelGeneralError({
                    name: 'parseAnswers',
                    location: 'Registration.goToNextStep.parseAnswers',
                    additionalInfo: err,
                });

                if (err.code !== 'address error') {
                    showBadRequestErrorMessage();
                }

                return;
            }

            // Helper for adding the original user input into the question object
            const addUserInputToQuestion = (questionObj, userInput) => {
                const dataType = getFirstNonEmptyKey(questionObj.dataType);

                if (
                    [
                        TEXT_FIELD,
                        BOOLEAN_FIELD,
                        INTEGER_FIELD,
                        FLOAT_FIELD,
                        SINGLE_SELECT_ENUM,
                        MULTI_SELECT_ENUM,
                        PASSWORD_TEXT_FIELD,
                    ].includes(dataType) ||
                    userInput === IDK
                ) {
                    if (
                        dataType === MULTI_SELECT_ENUM &&
                        !Array.isArray(userInput)
                    ) {
                        questionObj.dataType[dataType].userInput = [userInput];
                    } else {
                        questionObj.dataType[dataType].userInput = userInput;
                    }
                } else if (dataType === DATE_FIELD) {
                    const [month, day, year] = userInput.split('/');

                    questionObj.dataType[dataType].userInput = {
                        year: Number(year),
                        month: Number(month),
                        day: Number(day),
                    };
                }
            };

            const questionIdArray = [];

            // For adding the original user input into the question object
            questions.forEach(question => {
                const qId = question.questionId.id;
                const dataType = getFirstNonEmptyKey(question.dataType);

                // Save question ID for later validation.
                questionIdArray.push(qId);

                if (answerHash[qId]) {
                    if (
                        dataType !== COMPOUND_FIELD &&
                        dataType !== UNION_FIELD
                    ) {
                        addUserInputToQuestion(question, answerHash[qId]);
                    } else if (dataType === COMPOUND_FIELD) {
                        const subAnswerHash = answerHash[qId];

                        question.dataType[COMPOUND_FIELD].questionsList.forEach(
                            subQuestion => {
                                if (subAnswerHash[subQuestion.questionId.id]) {
                                    addUserInputToQuestion(
                                        subQuestion,
                                        subAnswerHash[subQuestion.questionId.id]
                                    );
                                }
                            }
                        );
                    }
                }
            });

            try {
                const submitAnswersResponse = await submitAnswers(answers);
                const {
                    accountCreationPossible,
                    invalidAnswers,
                    // sectionComplete,
                    // sectionContainsMoreElements,
                    badRequest,
                } = submitAnswersResponse;

                if (invalidAnswers || badRequest) {
                    // TODO: Display proper message.
                    setIsLoading(false);
                    setRegistrationStepLoader(false);

                    const errorType = getFirstNonEmptyKey(
                        invalidAnswers?.validationResultsList[0]
                            ?.errorTypesList[0]
                    );

                    // dispatch corresponding regcode error modal on regcode question when INVALID_REG_CODE
                    if (
                        errorType === INVALID_REG_CODE &&
                        questionIdArray.includes(QuestionId.Values?.REG_CODE)
                    ) {
                        const invalidRegCodeDetails = getObjectKeyByValue(
                            ValidationError.InvalidRegCode.RegCodeError,
                            invalidAnswers?.validationResultsList[0]
                                ?.errorTypesList[0]?.invalidRegCode
                                ?.regCodeError
                        );

                        switch (invalidRegCodeDetails) {
                            case 'INVALID':
                                showBadRequestErrorMessage();
                                break;
                            case 'NOT_FOUND':
                                showNotFoundRegcodeMessage();
                                break;
                            case 'INACTIVE':
                                showClientCappedMessage();
                                break;
                            default:
                                showBadRequestErrorMessage();
                        }
                    }

                    // dispatch internationalNumberNotAllowed error modal on phone question when INVALID_PHONE_NUMBER
                    if (
                        errorType === INVALID_PHONE_NUMBER &&
                        questionIdArray.includes(
                            QuestionId.Values?.PHONE_MOBILE
                        )
                    ) {
                        // currently there is only one message in this invalidPhoneNumber error type
                        const clientNameFromError =
                            invalidAnswers?.validationResultsList[0]
                                ?.errorTypesList[0]?.invalidPhoneNumber
                                ?.internationalNumberNotAllowed?.clientName;

                        showInternationalNumberNotAllowedErrorMessage(
                            clientNameFromError
                        );
                    }

                    // dispatch account already exists modal on email question when emailInUse
                    if (
                        errorType === EMAIL_IN_USE &&
                        (questionIdArray.includes(QuestionId.Values?.EMAIL) ||
                            questionIdArray.includes(
                                QuestionId.Values?.GUARDIAN_EMAIL
                            ))
                    ) {
                        showAccountAlreadyExistsMessage();
                    }

                    // dispatch under age modal on dob question when underflow
                    // Todo: will update when granular error ready
                    if (
                        errorType === UNDERFLOW &&
                        (questionIdArray.includes(
                            QuestionId.Values?.BIRTH_DATE
                        ) ||
                            questionIdArray.includes(
                                QuestionId.Values?.POLICY_HOLDER_BIRTH_DATE
                            ))
                    ) {
                        showUnderAgeMessage();
                    }

                    // dispatch general error modal on ILLEGAL_SCALAR_VALUE
                    // Todo: hardcode here to disable error modal on email page and Mobile Phone Number, will remove when BE granular error ready
                    if (
                        errorType === ILLEGAL_SCALAR_VALUE &&
                        !questionIdArray.includes(QuestionId.Values?.EMAIL) &&
                        !questionIdArray.includes(
                            QuestionId.Values?.FIRST_NAME
                        ) &&
                        !questionIdArray.includes(
                            QuestionId.Values?.LAST_NAME
                        ) &&
                        !questionIdArray.includes(
                            QuestionId.Values?.PHONE_MOBILE
                        )
                    ) {
                        showBadRequestErrorMessage();
                    }

                    if (
                        (errorType === ILLEGAL_SCALAR_VALUE ||
                            errorType === INVALID_ZIP_CODE) &&
                        questionIdArray.includes(QuestionId.Values?.ZIP) &&
                        invalidAnswers?.validationResultsList[0]?.questionId
                            ?.id === QuestionId.Values?.ZIP
                    ) {
                        showZipcodeNotSupported();
                    }

                    // invalid Answers already logged in all REG 5.0 Page Error event

                    if (!invalidAnswers) {
                        mixpanelGeneralError({
                            name: `SubmitAnswers Response: 'Bad Request'`,
                            location: 'Registration.goToNextStep',
                            additionalInfo: submitAnswersResponse,
                        });
                    }

                    setQuestionErrors(
                        invalidAnswers?.validationResultsList || []
                    );

                    if (badRequest) {
                        // TODO: handle badRequest.errorsList
                    }

                    return;
                } else if (accountCreationPossible) {
                    const error = await createAndAuthenticateAccount();

                    if (error) {
                        setIsLoading(false);
                        setRegistrationStepLoader(false);
                        mixpanelGeneralError({
                            name: 'createAccount gRPC Error',
                            location:
                                'Registration.goToNextStep.createAndAuthenticateAccount',
                            additionalInfo: error,
                        });
                        dispatch(error);

                        return;
                    } else {
                        accountCreated = true;
                    }
                }

                // Save email address for BH sso.
                const emailAnswer = answers.find(
                    answer => answer.questionId?.id === QuestionId.Values?.EMAIL
                );

                if (emailAnswer) {
                    setUserEmail(emailAnswer.value);
                }

                // Check if its TARGET_PROGRAMS question and fire mixpanel event for it
                if (
                    isSpecificQuestion(
                        currentRegistrationStep,
                        'TARGET_PROGRAMS'
                    )
                ) {
                    const storedStepNumber = getStepNumber();

                    const isDisqualified =
                        storedStepNumber &&
                        storedStepNumber !==
                            currentRegistrationStep?.context?.stepNumber;

                    const targetPrograms =
                        currentRegistrationStep.questionsToAsk?.questionsList ||
                        [];

                    const targetProgramsOffered = targetPrograms.find(
                        answer =>
                            answer.questionId?.id ===
                            QuestionId.Values.TARGET_PROGRAMS
                    );

                    const targetProgramsAnswer = answers.find(
                        answer =>
                            answer.questionId?.id ===
                            QuestionId.Values?.TARGET_PROGRAMS
                    );

                    mixpanelProgramSelectionClick(
                        targetProgramsAnswer?.value,
                        targetProgramsOffered?.dataType?.multiSelectEnum?.answerCodesList.filter(
                            program =>
                                !targetProgramsAnswer?.value.includes(program)
                        ),
                        isDisqualified ? 2 : 1
                    );
                }

                if (questionIdArray.includes(QuestionId.Values?.PASSWORD)) {
                    setIsVisited(true);
                }
            } catch (error) {
                setIsLoading(false);
                setRegistrationStepLoader(false);
                mixpanelGeneralError({
                    name: 'submitAnswers gRPC Error',
                    location: 'Registration.goToNextStep.submitAnswer',
                    additionalInfo: error,
                });

                if (error.message === 'salesforceU13Error') {
                    showU13DisqualifiedMemberSupportErrorMessage();
                } else {
                    showBadRequestErrorMessage();
                }

                return;
            }

            // Check if its EMAIL question to set sign in button
            if (isSpecificQuestion(currentRegistrationStep, 'EMAIL')) {
                setIsEmailQuestionAsked(true);
            }

            // Mixpanel page pass event track if no error
            mixpanelPagePass(currentRegistrationStep);

            if (amplitudeFlag) {
                AmplitudeUtils.trackPagePass(currentRegistrationStep);
            }
        }

        let navigateNextResponse;

        try {
            navigateNextResponse = await getDataForNextStep(
                currentSectionIndex,
                currentSectionPathname
            );
        } catch (error) {
            setIsLoading(false);
            setRegistrationStepLoader(false);
            mixpanelGeneralError({
                name: 'getDataForNextStep',
                location: 'Registration.gotoNextStep.getDataForNextStep',
                additionalInfo: error,
            });
            showBadRequestErrorMessage();

            return;
        }

        const {
            accountCreationPossible,
            endOfSectionReached,
            nextStep,
            unableToContinue,
            navigateBackPossible,
            redirectUrl,
            title,
        } = navigateNextResponse;

        if (redirectUrl) {
            setCallBackUrl(redirectUrl.value);
        }

        if (title) {
            setTitleFromGRPC(title);

            if (nextStep || unableToContinue) {
                const displayTitle = getDisplayTitleFromSection(
                    nextStep ? nextStep : unableToContinue,
                    t
                );

                setDisplayTitle(displayTitle);
            }
        }

        // hardcode for REGCON-1722 if CongratsOnBehaviorHealthPage need to disable back button, proper handling should on BE navigateBackPossible flag
        const isCongratsOnBehaviorHealthPage =
            nextStep?.contentId === 'CongratsOnBehaviorHealth';

        if (isCongratsOnBehaviorHealthPage) {
            setIsBackButtonDisabled(false);
        } else {
            setIsBackButtonDisabled(navigateBackPossible);
        }

        const readToCreateAccount =
            accountCreationPossible ||
            endOfSectionReached?.accountCreationPossible;

        if (readToCreateAccount) {
            const error = await createAndAuthenticateAccount();

            if (error) {
                showAccountCreateErrorMessage();

                return;
            } else {
                accountCreated = true;
            }
        }

        if (nextStep) {
            if (nextStep.contentId === OneAppIntroduction) {
                setIsOneAppIntroductionPage(true);
            } else {
                setIsOneAppIntroductionPage(false);
            }

            // mixpanel log page land when there is next step
            mixpanelPageLand(nextStep);

            if (amplitudeFlag) {
                AmplitudeUtils.trackPageLand(nextStep);
            }
            const {sectionId, stepNumber, numberOfSteps, answersList} =
                nextStep.context || {};
            let nextSection = {};

            if (sectionId === consentSection.id) {
                nextSection = consentSection;
            } else {
                nextSection = sections.find(
                    section => section.id === sectionId
                );
            }

            let confirmEligibilityRegCodeAnswer;

            // set regcode if we find it in BE return
            if (answersList) {
                const regCodeQuestion = answersList.find(
                    answer =>
                        answer.questionId?.id === QuestionId.Values.REG_CODE
                );

                const confirmEligibilityRegCodeQuestion = answersList.find(
                    answer =>
                        answer.questionId?.id ===
                        QuestionId.Values.CONFIRM_ELIGIBILITY_FILE_REG_CODE
                );

                const regCodeAnswer = regCodeQuestion?.value?.text;

                confirmEligibilityRegCodeAnswer =
                    confirmEligibilityRegCodeQuestion?.value?.pb_boolean;

                if (regCodeAnswer) {
                    setRegCode(regCodeAnswer);
                }
            }

            const questionsList = nextStep.questionsToAsk?.questionsList || [];

            const isRegcodeQuestion = questionsList.find(
                q => q.questionId?.id === QuestionId.Values.REG_CODE
            );

            // if confirmEligibilityRegCodeAnswer is false and its on regcode question. erase the default value
            if (
                confirmEligibilityRegCodeAnswer === false &&
                isRegcodeQuestion
            ) {
                questionsList.reduce((list, question) => {
                    if (
                        question.questionId?.id === QuestionId.Values.REG_CODE
                    ) {
                        question.dataType.textField.previousAnswer.value = null;
                    }

                    return list.concat(question);
                }, []);
            }

            const validationErrorsList = questionsList.reduce(
                (list, question) => {
                    return list.concat({
                        questionId: question.questionId,
                        errorTypesList: question.validationErrorsList,
                    });
                },
                []
            );

            setQuestionErrors(validationErrorsList);

            if (nextSection) {
                // It is a valid section and construct the URL for next step.

                let nextStepUrl = `${Config.registerStartUrl}/${nextSection.pathname}/${numberOfSteps}/${stepNumber}`;

                if (accountCreated) {
                    nextStepUrl += '?account_created=true';
                }

                stepUrls.splice(currentStepIndex + 1);
                stepUrls.push(nextStepUrl);

                // Update registration history to local storage or cookie.
                setRegistrationStepUrls(stepUrls);
                // Do the redirection.
                history.push(nextStepUrl);
                // Update data.
                setCurrentRegistrationStep(nextStep);
            } else {
                // TODO: Display proper message.
            }
        } else if (unableToContinue) {
            // mixpanel log page land when it is not able to continue.
            mixpanelUnableToContinue(unableToContinue);

            if (amplitudeFlag) {
                AmplitudeUtils.trackUnableToContinue(unableToContinue);
            }
            setCurrentRegistrationStep(unableToContinue);
        } else {
            // TODO: Display proper message.
        }
        setIsLoading(false);
        setRegistrationStepLoader(false);
    };

    // Navigate among registration steps.
    // Used to keep track of current
    useNavigation(location.pathname, history);

    // For page, use navigateHere to get the correct question and section
    useEffect(() => {
        const loadCurrentStep = async () => {
            setIsLoading(true);
            setRegistrationStepLoader(true);

            try {
                let sectionIndex = currentSectionIndex;
                let found = false;
                let callCount = 0;

                while (!found && callCount < sections.length) {
                    let currentSection = sections[sectionIndex];
                    /* eslint-disable no-await-in-loop */
                    let navigateHereResponse;

                    if (isOneApp && userProgress) {
                        navigateHereResponse = userProgress;
                        found = true;
                        callCount = sections.findIndex(
                            section =>
                                section.id ===
                                userProgress?.currentStep?.context?.sectionId
                        );
                        currentSection = sections[callCount];
                    } else {
                        navigateHereResponse = await navigateHere(
                            sections[sectionIndex].id
                        );
                    }

                    const {
                        currentStep,
                        badRequest,
                        endOfSectionReached,
                        navigateBackPossible,
                        redirectUrl,
                        title,
                        accountCreationPossible,
                    } = navigateHereResponse;

                    if (accountCreationPossible) {
                        const error = await createAndAuthenticateAccount();

                        if (error) {
                            setIsLoading(false);
                            setRegistrationStepLoader(false);
                            mixpanelGeneralError({
                                name: 'createAccount gRPC Error',
                                location:
                                    'Registration.navigateHere.createAndAuthenticateAccount',
                                additionalInfo: error,
                            });
                            dispatch(error);

                            return;
                        }
                    }

                    if (redirectUrl) {
                        setCallBackUrl(redirectUrl.value);
                    }

                    if (title) {
                        setTitleFromGRPC(title);

                        if (currentStep) {
                            const displayTitle = getDisplayTitleFromSection(
                                currentStep,
                                t
                            );

                            setDisplayTitle(displayTitle);
                        }
                    }

                    setIsBackButtonDisabled(navigateBackPossible);

                    if (badRequest) {
                        // turn off the loop condition
                        found = true;

                        throw new Error(JSON.stringify(badRequest));
                    }

                    if (currentStep) {
                        if (
                            currentStep.contentId === SsoWarmWelcome &&
                            !checkIsGivenQuestion(
                                currentStep,
                                'TERMS_AND_CONDITIONS'
                            )
                        ) {
                            navigateHereResponse =
                                generateResponseForSsoWarmWelcome(
                                    navigateHereResponse
                                );

                            setUserProgress(navigateHereResponse);
                        }

                        if (currentStep.contentId === OneAppIntroduction) {
                            setIsOneAppIntroductionPage(true);
                        } else {
                            setIsOneAppIntroductionPage(false);
                        }

                        found = true;
                        setCurrentRegistrationStep(currentStep);

                        // Mixpanel page land event track.
                        mixpanelPageLand(currentStep);

                        if (amplitudeFlag) {
                            AmplitudeUtils.trackPageLand(currentStep);
                        }
                        try {
                            PIWIKUtils.trackPageLand();
                        } catch (error) {
                            mixpanelGeneralError({
                                name: 'Piwik Tracking Error',
                                location:
                                    'Registartion.async.getCurrentStepOnLoad',
                                additionalInfo: error,
                            });
                        }

                        // Construct the URL
                        const {numberOfSteps, stepNumber} = currentStep.context;
                        const stepUrl = `${Config.registerStartUrl}/${currentSection.pathname}/${numberOfSteps}/${stepNumber}`;

                        // if the url is different, set
                        if (location.pathname !== stepUrl) {
                            history.replace(stepUrl);
                        }

                        // if the url was not found, add it to Registration Step Urls
                        if (stepUrls.indexOf(stepUrl) === -1) {
                            stepUrls.push(stepUrl);

                            // Update registration history to local storage or cookie.
                            setRegistrationStepUrls(stepUrls);
                        }
                    } else if (endOfSectionReached) {
                        // move onto next section
                        sectionIndex++;
                        sectionIndex %= sections.length;
                        callCount++;
                    }
                }
            } catch (error) {
                mixpanelGeneralError({
                    name: 'navigateHere',
                    location: 'Registration.getCurrentStepOnLoad',
                    additionalInfo: error,
                });
                showBadRequestErrorMessage();
            } finally {
                setIsLoading(false);
                setRegistrationStepLoader(false);
            }
        };

        loadCurrentStep();
        // eslint-disable-next-line
    }, []);

    // Got data for page load by forward or backward browser action.
    // Can handle case when use skips
    useEffect(() => {
        // Get flag whether the step load by app or browser action.
        // Also used to skip when navigateHere changes the url onload
        const isLoadFromApp = getRegistrationStepLoader();

        /*
         * For below situations do not need to load data.
         * If step load by app action of next or back button.
         * If current step is not valid.
         * If current section is not valid.
         */

        if (isLoadFromApp || userProgress) {
            return;
        }

        /**
         * Get section index of the step based on the step index within registration history.
         * @param {number} stepIndex - Index of step within registration history.
         * @returns {number} - Index of section.
         */
        const getSectionIndexByStepIndex = stepIndex => {
            const stepUrl = stepUrls[stepIndex] || '';
            const [, , stepPathname] = stepUrl.split('/');

            return sections.findIndex(s => s.pathname === stepPathname);
        };

        /**
         * Get section index of the step based on the step index within registration history.
         * @param {number} stepIndex - Index of step within registration history.
         * @returns {boolean} - Index of section.
         */
        const getIsConsentSectionByStepIndex = stepIndex => {
            const stepUrl = stepUrls[stepIndex] || '';
            const [, , stepPathname] = stepUrl.split('/');

            return stepPathname === consentSection.pathname;
        };

        /**
         * Get data for previous or next step or steps.
         * This function is for handling browser actions.
         * @returns {Promise<*>} - Promise.
         */
        const getDataForBackAndForth = async () => {
            let navigateResponse;
            let sectionIndex = getSectionIndexByStepIndex(previousStepIndex);

            setIsLoading(true);

            if (previousStepIndex < currentStepIndex) {
                if (isSpecificQuestion(currentRegistrationStep, 'EMAIL')) {
                    setIsEmailQuestionAsked(true);
                }

                try {
                    for (
                        let i = previousStepIndex,
                            stepIndex = previousStepIndex;
                        i < currentStepIndex;
                        i++, stepIndex++
                    ) {
                        const isConsentSection =
                            getIsConsentSectionByStepIndex(stepIndex);

                        if (isConsentSection) {
                            navigateResponse = await navigateNext(
                                consentSection.id
                            );
                        } else {
                            navigateResponse = await navigateNext(
                                sections[sectionIndex].id
                            );
                        }

                        const {
                            // unableToGoBack,
                            badRequest,
                            endOfSectionReached,
                            nextStep,
                            navigateBackPossible,
                            // unableToContinue,
                            redirectUrl,
                            title,
                        } = navigateResponse;

                        if (redirectUrl) {
                            setCallBackUrl(redirectUrl.value);
                        }

                        if (title) {
                            setTitleFromGRPC(title);

                            if (nextStep) {
                                const displayTitle = getDisplayTitleFromSection(
                                    nextStep,
                                    t
                                );

                                setDisplayTitle(displayTitle);
                            }
                        }

                        setIsBackButtonDisabled(navigateBackPossible);

                        if (endOfSectionReached) {
                            if (sectionIndex === sections.length) {
                                break;
                            }

                            // Move to next section.
                            if (isConsentSection) {
                                sectionIndex = 0;
                            } else {
                                sectionIndex++;
                            }

                            // Adjust step index.
                            i--;
                        } else if (nextStep) {
                            if (nextStep.contentId === OneAppIntroduction) {
                                setIsOneAppIntroductionPage(true);
                            } else {
                                setIsOneAppIntroductionPage(false);
                            }

                            // check if it has the email question and dispatch(emailSet(true)) if found and we are navigating pass it
                            if (
                                isSpecificQuestion(nextStep, 'EMAIL') &&
                                i <= currentStepIndex - 1
                            ) {
                                setIsEmailQuestionAsked(true);
                            }
                            setCurrentRegistrationStep(nextStep);
                        } else if (badRequest) {
                            throw new Error(JSON.stringify(badRequest));
                        } else {
                            // unableToGoBack || unableToContinue
                            // TODO: error message for user for these cases
                        }
                    }
                } catch (error) {
                    mixpanelGeneralError({
                        name: 'navigateNext (Browser Action)',
                        location:
                            'Registration.getDataForBackAndForth inside useEffect([location.pathname])',
                        additionalInfo: error,
                    });
                    showBadRequestErrorMessage();
                } finally {
                    setIsLoading(false);
                    setRegistrationStepLoader(false);
                }
            } else {
                try {
                    for (
                        let i = previousStepIndex,
                            stepIndex = previousStepIndex;
                        i > currentStepIndex;
                        i--, stepIndex--
                    ) {
                        const isConsentSection =
                            getIsConsentSectionByStepIndex(stepIndex);

                        if (isConsentSection) {
                            navigateResponse = await navigateBack(
                                consentSection.id
                            );
                        } else {
                            navigateResponse = await navigateBack(
                                sections[sectionIndex].id
                            );
                        }

                        const {
                            // unableToGoBack,
                            beginningOfSectionReached,
                            previousStep,
                            badRequest,
                            navigateBackPossible,
                            // unableToContinue,
                            redirectUrl,
                            title,
                        } = navigateResponse;

                        if (redirectUrl) {
                            setCallBackUrl(redirectUrl.value);
                        }

                        if (title) {
                            setTitleFromGRPC(title);

                            if (previousStep) {
                                const displayTitle = getDisplayTitleFromSection(
                                    previousStep,
                                    t
                                );

                                setDisplayTitle(displayTitle);
                            }
                        }

                        setIsBackButtonDisabled(navigateBackPossible);

                        if (beginningOfSectionReached) {
                            if (sectionIndex === 0) {
                                break;
                            }

                            // Move to previous section.
                            if (isConsentSection) {
                                sectionIndex = 0;
                            } else {
                                sectionIndex--;
                            }

                            // Adjust step index.
                            i++;
                        } else if (previousStep) {
                            if (previousStep.contentId === OneAppIntroduction) {
                                setIsOneAppIntroductionPage(true);
                            } else {
                                setIsOneAppIntroductionPage(false);
                            }

                            // check if it has the email question and dispatch(emailSet(false)) if found
                            if (isSpecificQuestion(previousStep, 'EMAIL')) {
                                setIsEmailQuestionAsked(false);
                            }
                            setCurrentRegistrationStep(previousStep);
                        } else if (badRequest) {
                            throw new Error(JSON.stringify(badRequest));
                        } else {
                            // unableToGoBack || unableToContinue
                            // TODO: error message for user for these cases
                        }
                    }
                } catch (error) {
                    mixpanelGeneralError({
                        name: 'navigateBack (Browser Action)',
                        location:
                            'Registration.getDataForBackAndForth inside useEffect([location.pathname])',
                        additionalInfo: error,
                    });
                    showBadRequestErrorMessage();
                } finally {
                    setIsLoading(false);
                    setRegistrationStepLoader(false);
                }
            }
        };

        if (previousStepIndex !== currentStepIndex) {
            // Load data for previous or next step or steps.
            // TODO: Display message for forward action if value of forms changed.
            getDataForBackAndForth();
        }

        // TODO: Clean other dependencies.
        // eslint-disable-next-line
    }, [location.pathname]);

    const showBackButton = () => isBackButtonDisabled;
    const showNextButton = () => !disableNavBtn.nextBtn;
    const questionsList = sectionInfo?.questionsToAsk?.questionsList || [];
    const hasInfo = questionsList.some(question => {
        const {questionId} = question;
        const {id = -1} = questionId || {};
        // Get question id in string format by value.
        const questionName = getQuestionNameById(id);
        // Get question UI configuration.

        return questionConfig[questionName]?.info;
    });

    const hasConfirmDOBQuestion = questionsList.some(
        q => q.questionId.id === QuestionId.Values.CONFIRM_BIRTH_DATE
    );

    const isPreferencesPage = sectionInfo.contentId === Preferences;

    // Callback redirect url
    const redirectToCallBackUrl = () => {
        const callBackUrl = getCallBackUrl();

        if (callBackUrl) {
            cleanSessionInfo(true);
            window.location.href = callBackUrl;
        }
    };

    const exitCoachingPreferences = () => {
        gotoNextStep();
    };

    return (
        <>
            <IdleTimer
                element={document}
                onIdle={handleIdle}
                debounce={Config.idleTimerDebounce}
                timeout={Config.idleTimerTimeout}
            />
            <div
                className={cn(css.card, {
                    [css.removeInfo]: isSalesforce,
                })}
            >
                {sectionInfo && !isSalesforce && !isOneApp && (
                    <Info data={sectionInfo} />
                )}
                <main
                    className={cn(css.questionsContainer, {
                        [css.telemedicineQuestionsContainer]: isOneApp,
                        [css.removeInfo]: isSalesforce,
                        [css.radius]: hasInfo,
                        [css.mobileRadius]: !hasInfo,
                    })}
                    role="main"
                >
                    <div className={css.questionsSubContainer}>
                        {!isOneAppIntroductionPage &&
                            isOneApp &&
                            !isLoading && (
                                <span className={css.backButton}>
                                    {showBackButton() && (
                                        <span>
                                            <NavigationButton
                                                type="PREVIOUS"
                                                icon={<ArrowLeftDefault />}
                                                iconPosition="left"
                                                onClick={gotoPreviousStep}
                                                label={t('buttonsLabel.back')}
                                                sectionInfo={sectionInfo}
                                                setNavigationLabel={
                                                    setNavigationLabel
                                                }
                                            />
                                        </span>
                                    )}
                                </span>
                            )}
                        {!isSalesforce &&
                            !isOneApp &&
                            !hasConfirmDOBQuestion && (
                                <ProgressBar
                                    location={location}
                                    sections={sections}
                                    isOneApp={isOneApp}
                                />
                            )}
                        <Form ref={formRef} id="interview" className={css.form}>
                            {isLoading ? (
                                <Loader className="Loader-module-root" />
                            ) : isOneApp && isPreferencesPage ? (
                                <CoachingPreferences
                                    sectionInfo={sectionInfo}
                                    isOneApp
                                    redirectToCallBackUrl={
                                        redirectToCallBackUrl
                                    }
                                    createAndAuthenticateAccount={
                                        createAndAuthenticateAccount
                                    }
                                    exitCoachingPreferences={
                                        exitCoachingPreferences
                                    }
                                />
                            ) : (
                                sectionInfo && (
                                    <Section
                                        sectionInfo={sectionInfo}
                                        validationErrorsList={questionErrors}
                                        setNavigationLabel={setNavigationLabel}
                                        isOneApp={isOneApp}
                                        formRef={formRef}
                                    />
                                )
                            )}
                            {(showBackButton() || showNextButton()) && (
                                <div
                                    className={cn(
                                        css.navigationButtonsContainer,
                                        {
                                            [css.oneAppButtonContainer]:
                                                isOneApp,
                                        }
                                    )}
                                >
                                    {!isOneApp &&
                                        !isLoading &&
                                        showBackButton() && (
                                            <NavigationButton
                                                type="PREVIOUS"
                                                onClick={gotoPreviousStep}
                                                label={t('buttonsLabel.back')}
                                                sectionInfo={sectionInfo}
                                                setNavigationLabel={
                                                    setNavigationLabel
                                                }
                                            />
                                        )}
                                    {showNextButton() &&
                                        !(isOneApp && isPreferencesPage) &&
                                        !isLoading && (
                                            <>
                                                {isImpersonation &&
                                                    isOneAppIntroductionPage && (
                                                        <div className={css.i}>
                                                            ”As part of the
                                                            Condition Management
                                                            enrollment, I am
                                                            going to ask you a
                                                            few health
                                                            questions”
                                                        </div>
                                                    )}
                                                <NavigationButton
                                                    type="NEXT"
                                                    onClick={gotoNextStep}
                                                    label={navigationNextLabel}
                                                    sectionInfo={sectionInfo}
                                                    setNavigationLabel={
                                                        setNavigationLabel
                                                    }
                                                    active
                                                />
                                            </>
                                        )}
                                </div>
                            )}
                        </Form>
                    </div>
                </main>
            </div>
            {sectionInfo && !isSalesforce && (
                <U13Experience data={sectionInfo} />
            )}
        </>
    );
};

Registration.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
};

export default Registration;
