import {useTranslation} from 'react-i18next';
import Countdown from 'react-countdown';
import Loader from '@teladoc/pulse/ui/Loader';
import Arg from '@livongo/arg';
import {cleanSessionInfo} from 'utilities/utils';
import css from './Info.scss';

const Info = () => {
    const {t} = useTranslation('app');
    const arg = Arg.all();
    const callBackUrl = 'callback';
    const info = 'info';
    const handleComplete = () => {
        cleanSessionInfo(true);

        if (arg[callBackUrl]) {
            window.location.href = decodeURIComponent(arg[callBackUrl]);
        } else {
            window.location.href = process.env.ONEAPP_LOGIN_URL;
        }
    };

    // this info route can be reused to display other info when needed, switch the render base on the info param

    switch (arg[info]) {
        case 'ssoError':
            return (
                <Countdown
                    date={Date.now() + 5000}
                    renderer={() => (
                        <div className={css.root}>
                            <Loader className="Loader-module-root" />
                            <div>&nbsp;</div>
                            <p>{t(`${arg[info]}.p1`)}</p>
                            <p>{t(`${arg[info]}.p2`)}</p>
                        </div>
                    )}
                    onComplete={handleComplete}
                />
            );
    }
};

export default Info;
