import PropTypes from 'prop-types';
import {useTranslation, Trans} from 'react-i18next';
import {getStoredLang} from '@teladoc/pulse/ui/App/g11n';
import Button from '@teladoc/pulse/ui/Button';
import ScreenReader from 'common/screen-reader/ScreenReader';
import Config from 'config';
import {mixpanelPdfViewedEvent} from 'utilities/mixpanel-utils';
import {getCallBackUrl, cleanSessionInfo} from 'utilities/utils';
import css from './ContentGeneric.scss';

const ContentGeneric = ({infoDetails, contentId, isOneApp}) => {
    const {
        title,
        paragraph,
        paragraph2,
        paragraph3,
        btnPDF,
        oneAppTitle,
        oneAppButton,
    } = infoDetails;
    const {t} = useTranslation('content');
    const locale = getStoredLang().toLowerCase();
    const onPdfClick = () => {
        window.open(
            `${Config.healthyEating}?locale=${locale}`,
            '_blank',
            'noopener noreferrer'
        );
        mixpanelPdfViewedEvent('Healthy Eating Guide', '', contentId);
    };

    const onGohomeClick = () => {
        const redirectUrl = getCallBackUrl();

        cleanSessionInfo(true);
        window.location.href = redirectUrl;
    };

    return (
        <div>
            {title && (
                <h1 className={css.h1}>
                    {isOneApp && oneAppTitle
                        ? t(oneAppTitle.key, oneAppTitle.interpolation || {})
                        : t(title.key, title.interpolation || {})}
                </h1>
            )}

            {paragraph &&
                (isOneApp ? (
                    <p>
                        <Trans
                            i18nKey={`content:${contentId}.oneAppParagraph`}
                            components={[
                                /* eslint-disable react/jsx-key */
                                <a
                                    href={`${Config.healthyEating}?locale=${locale}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={css.url}
                                >
                                    <ScreenReader />
                                </a>,
                                /* eslint-enable react/jsx-key */
                            ]}
                        />
                    </p>
                ) : (
                    <p className={css.p}>
                        {t(paragraph.key, paragraph.interpolation || {})}
                    </p>
                ))}
            {paragraph2 && (
                <p className={css.p}>
                    {t(paragraph2.key, paragraph2.interpolation || {})}
                </p>
            )}
            {paragraph3 && (
                <p>{t(paragraph3.key, paragraph3.interpolation || {})}</p>
            )}

            {btnPDF && !isOneApp && (
                <Button
                    className={css.btnPDF}
                    variant="primary"
                    onClick={onPdfClick}
                >
                    {t(btnPDF.key, btnPDF.interpolation || {})}
                </Button>
            )}
            {oneAppButton && isOneApp && (
                <Button
                    className={css.oneAppButton}
                    variant="primary"
                    onClick={onGohomeClick}
                >
                    {t(oneAppButton.key)}
                </Button>
            )}
        </div>
    );
};

ContentGeneric.propTypes = {
    infoDetails: PropTypes.object.isRequired,
    contentId: PropTypes.string.isRequired,
    isOneApp: PropTypes.bool,
};

export default ContentGeneric;
