import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import Config from 'config';
import {navigationBtnDisable} from 'store/navigationButton/navigationBtnDisable-actions';
import ContentGeneric from './ContentGeneric';
import U13U18Disqualification from './U13U18Disqualification';
import CustomConfirmationNoRecruitableMatch from './CustomConfirmationNoRecruitableMatch';
import OneAppEnrollmentBlocked from './OneAppEnrollmentBlocked';
import ReEnrollmentBlocked from './ReEnrollmentBlocked';

const {memberSupportFormattedPhoneNumber} = Config;

const Disqualification = ({contentId, clientName, isOneApp}) => {
    const dispatch = useDispatch();

    const disqualifyDetails = {
        title: {
            key: isOneApp ? `${contentId}.oneAppTitle` : `${contentId}.title`,
        },
        paragraph: {
            key: isOneApp
                ? `${contentId}.oneAppParagraph`
                : `${contentId}.paragraph`,
            interpolation: {memberSupportFormattedPhoneNumber},
        },
        paragraph2:
            contentId === 'EnrollmentCapReached' ||
            contentId === 'DisqualificationPregnancy' ||
            contentId === 'DisqualificationUnderAge'
                ? {
                      key: `${contentId}.paragraph2`,
                  }
                : null,
        paragraph3:
            contentId === 'EnrollmentCapReached'
                ? {
                      key: `${contentId}.paragraph3`,
                  }
                : null,
        btnPDF: {key: `${contentId}.btnPDF`},
        oneAppButton: {key: `${contentId}.oneAppButton`},
    };

    /* NavigationButton must be disabled in this component */
    useEffect(() => {
        let obj = {backBtn: true, nextBtn: true};

        dispatch(navigationBtnDisable(obj));

        return () => {
            obj = {backBtn: false, nextBtn: false};

            dispatch(navigationBtnDisable(obj)); // default value
        };
        // eslint-disable-next-line
    }, []);

    switch (contentId) {
        case 'U13Disqualified':
        case 'U18Disqualified':
            return (
                <U13U18Disqualification
                    contentId={contentId}
                    clientName={clientName}
                />
            );
        case 'CustomConfirmationNoRecruitableMatch':
            return (
                <CustomConfirmationNoRecruitableMatch contentId={contentId} />
            );

        case 'OneAppEnrollmentBlocked':
            return <OneAppEnrollmentBlocked contentId={contentId} />;

        case 'ReEnrollmentBlocked':
            return <ReEnrollmentBlocked contentId={contentId} />;

        default:
            return (
                <ContentGeneric
                    infoDetails={disqualifyDetails}
                    contentId={contentId}
                    isOneApp={isOneApp}
                />
            );
    }
};

Disqualification.propTypes = {
    contentId: PropTypes.string.isRequired,
    clientName: PropTypes.string.isRequired,
    isOneApp: PropTypes.bool,
};

export default Disqualification;
