const featureToggleConfig = require(`./${
    process.env.APP_ENV === 'local' ? 'integration' : process.env.APP_ENV
}.json`);

export default featureToggleConfig;

/**
 * Checks to see if a feature in the feature flag list is enabled
 * @static
 * @function isFeatureEnabled
 * @returns boolean
 * @param featureList
 * @param featureName
 * @param isOneApp
 */

export const isFeatureEnabled = (featureList, featureName, isOneApp) => {
    return featureList?.some(
        feature =>
            feature.name === featureName &&
            ((!isOneApp && feature.legacyActive) ||
                (isOneApp && feature.oneAppActive))
    );
};

export const FEATURE_FLAG_NAMES = {
    testToggle: 'test_toggle',
};
