// Function to load paths
export const loadPaths = async () => {
    const module = await import(
        /* webpackChunkName: "websdk-paths" */ '@td/websdk'
    );

    return module.paths;
};

// Function to load WebSDK
export const loadWebSDK = async () => {
    const module = await import(
        /* webpackChunkName: "websdk-WebSDK" */ '@td/websdk'
    );

    return module.WebSDK;
};

// Function to load fetchChronicCareToken
export const loadFetchChronicCareToken = async () => {
    const module = await import(
        /* webpackChunkName: "websdk-fetchChronicCareToken" */ '@td/websdk'
    );

    return module.fetchChronicCareToken;
};
