import isBefore from 'date-fns/isBefore';
import {getStoredLang} from '@teladoc/pulse/ui/App/g11n';
import StorageUtils from '@livongo/utils/storage';
import Arg from '@livongo/arg';
import UserAPI from 'api/rest/user-api';
import Config from 'config';
import {REGISTRATION, nodeTitleIds} from 'constants/app';
import {getTdocMemberId} from '../routes/medOpt/medOptCookieUtils';

const {
    applicationName,
    stepUrls,
    stepIndex,
    stepLoader,
    userUUID,
    userEmail,
    isAuthenticated,
    isVisited,
    userSessionExpires,
    grpcAccessToken,
    grpcAccessTokenTimeStamp,
    teladocSdkToken,
    webSdkInitialState,
    restApiAccessToken,
    restApiRefreshToken,
    restApiAccessCode,
    regCode,
    clientFriendlyName,
    vwoVariant,
    allOfferedPrograms,
    smsIframe,
    registrationContext,
    partner,
    isEmailQuestionAsked,
    callBackUrl,
    savedAddress,
    grpcTitle,
    displayTitle,
    oneAppMobileAppLink,
    ssoContext,
    oneAppPortal,
    correlationId,
    stepNumber,
    isRedirectFromTeams,
    upsellProgram,
    userProgress,
} = Config;

const {askGuardianInfo, askU18GuardianInfo} = nodeTitleIds;

export const devConsoleLog = (...data) => {
    if (process.env.APP_ENV === 'local') {
        // eslint-disable-next-line
        console.log(...data);
    }
};

/**
 * Set the application name prefix in localstorage so different applications can uses the same localstorage utility functions.
 * For instance, Registration will call getRegistrationStepUrl and get the data from key: 'registration_stepUrls'.
 * MedOpt will call the same function and get data from key: 'medOpts_stepUrls'.
 * @param {string} appName - The application name prefix.
 * @returns {null} - The property name.
 */
export const setApplication = appName => {
    StorageUtils.set({
        key: applicationName,
        value: appName,
    });
};

/**
 * Returns the application prefix name to add to keys for use with localstorage.
 * @returns {string} - application prefix name.
 */
export const getApplication = () => {
    return (
        StorageUtils.get({
            key: applicationName,
        }) || REGISTRATION
    );
};

/**
 * Returns the application prefix name with the key name
 * @param {string} key - The localstorage key name.
 * @returns {string} - application prefix name and key name.
 */
export const addApplicationPrefix = key => {
    const appName = getApplication();

    return `${appName}${key}`;
};

/**
 * Get object property name by value.
 * @param {object} obj - The object look for.
 * @param {object} value - The value to compare.
 * @returns {string|null} - The property name.
 */
export const getObjectKeyByValue = (obj, value) => {
    try {
        for (const key in obj) {
            if (obj[key] === value) {
                return key;
            }
        }

        return null;
    } catch (e) {
        return null;
    }
};

/**
 * Get the first non-empty property name of an object
 * @param {object} obj - The object look for.
 * @returns {string|null} - The property name.
 */
export const getFirstNonEmptyKey = obj => {
    for (const key in obj) {
        if (obj[key] !== null && obj[key]) {
            return key;
        }
    }

    return null;
};

export const extendStorageUtils = () => {
    StorageUtils.batchSet = objArray => {
        objArray.forEach(obj => {
            StorageUtils.set({
                key: obj.key,
                value: obj.value,
            });
        });
    };

    StorageUtils.batchRemove = objArray => {
        objArray.forEach(obj => {
            StorageUtils.remove({
                key: obj.key,
            });
        });
    };
};

export const getRegistrationStepUrls = () => {
    return (
        StorageUtils.get({
            key: addApplicationPrefix(stepUrls),
        }) || []
    );
};

export const getRegistrationStepIndex = () => {
    const index = StorageUtils.get({
        key: addApplicationPrefix(stepIndex),
    });

    return index === null ? -1 : index;
};

export const getRegistrationHistory = () => {
    return {
        stepUrls: getRegistrationStepUrls(),
        stepIndex: getRegistrationStepIndex(),
    };
};

export const getRegistrationStepLoader = () => {
    return StorageUtils.get({
        key: addApplicationPrefix(stepLoader),
    });
};

export const setRegistrationStepUrls = urls => {
    StorageUtils.set({
        key: addApplicationPrefix(stepUrls),
        value: urls,
    });
};

export const setRegistrationStepIndex = index => {
    StorageUtils.set({
        key: addApplicationPrefix(stepIndex),
        value: index,
    });
};

export const setRegistrationHistory = (urls, index) => {
    setRegistrationStepUrls(urls);
    setRegistrationStepIndex(index);
};

export const setRegistrationStepLoader = loader => {
    StorageUtils.set({
        key: addApplicationPrefix(stepLoader),
        value: loader,
    });
};

export const getUserUUID = () => {
    return StorageUtils.get({
        key: addApplicationPrefix(userUUID),
    });
};

export const setUserUUID = uuid => {
    StorageUtils.set({
        key: addApplicationPrefix(userUUID),
        value: uuid,
    });
};

export const getCorrelationId = () => {
    return StorageUtils.get({
        key: addApplicationPrefix(correlationId),
    });
};

export const setCorrelationId = id => {
    StorageUtils.set({
        key: addApplicationPrefix(correlationId),
        value: id,
    });
};

export const getUserEmail = () => {
    return StorageUtils.get({
        key: userEmail,
    });
};

export const setUserEmail = email => {
    StorageUtils.set({
        key: userEmail,
        value: email,
    });
};

export const getIsAuthenticated = () => {
    return StorageUtils.get({
        key: addApplicationPrefix(isAuthenticated),
    });
};

export const setIsAuthenticated = value => {
    StorageUtils.set({
        key: addApplicationPrefix(isAuthenticated),
        value,
    });
};

export const getIsVisited = () => {
    return StorageUtils.get({
        key: addApplicationPrefix(isVisited),
    });
};

export const setIsVisited = value => {
    StorageUtils.set({
        key: addApplicationPrefix(isVisited),
        value,
    });
};

export const getUserSessionExpires = () => {
    return StorageUtils.get({
        key: addApplicationPrefix(userSessionExpires),
    });
};

export const setUserSessionExpires = time => {
    StorageUtils.set({
        key: addApplicationPrefix(userSessionExpires),
        value: time,
    });
};

export const getGrpcAccessToken = () => {
    return StorageUtils.get({
        key: addApplicationPrefix(grpcAccessToken),
    });
};

export const setGrpcAccessToken = token => {
    StorageUtils.set({
        key: addApplicationPrefix(grpcAccessToken),
        value: token,
    });
};
export const getGrpcAccessTokenTimeStamp = () => {
    return StorageUtils.get({
        key: addApplicationPrefix(grpcAccessTokenTimeStamp),
    });
};

export const setGrpcAccessTokenTimeStamp = tokenTimeStamp => {
    StorageUtils.set({
        key: addApplicationPrefix(grpcAccessTokenTimeStamp),
        value: tokenTimeStamp,
    });
};

export const getWebSdkInitialState = () => {
    return StorageUtils.get({
        key: addApplicationPrefix(webSdkInitialState),
    });
};

export const setWebSdkInitialState = settings => {
    StorageUtils.set({
        key: addApplicationPrefix(webSdkInitialState),
        value: settings,
    });
};
export const getTeladocSdkToken = () => {
    return StorageUtils.get({
        key: addApplicationPrefix(teladocSdkToken),
    });
};

export const setTeladocSdkToken = token => {
    StorageUtils.set({
        key: addApplicationPrefix(teladocSdkToken),
        value: token,
    });
};

export const getRestApiAccessToken = () => {
    return StorageUtils.get({
        key: addApplicationPrefix(restApiAccessToken),
    });
};

export const setRestApiAccessToken = token => {
    StorageUtils.set({
        key: addApplicationPrefix(restApiAccessToken),
        value: token,
    });
};

export const getRestApiRefreshToken = () => {
    return StorageUtils.get({
        key: addApplicationPrefix(restApiRefreshToken),
    });
};

export const setRestApiRefreshToken = token => {
    StorageUtils.set({
        key: addApplicationPrefix(restApiRefreshToken),
        value: token,
    });
};

export const getRestApiAccessCode = () => {
    return StorageUtils.get({
        key: addApplicationPrefix(restApiAccessCode),
    });
};

export const setRestApiAccessCode = code => {
    StorageUtils.set({
        key: addApplicationPrefix(restApiAccessCode),
        value: code,
    });
};

export const getRegCode = () => {
    return StorageUtils.get({
        key: addApplicationPrefix(regCode),
    });
};

export const setRegCode = code => {
    StorageUtils.set({
        key: addApplicationPrefix(regCode),
        value: code,
    });
};

export const getClientFriendlyName = () => {
    return StorageUtils.get({
        key: clientFriendlyName,
    });
};

export const setClientFriendlyName = name => {
    StorageUtils.set({
        key: clientFriendlyName,
        value: name,
    });
};

export const getVWOVariant = () => {
    return StorageUtils.get({
        key: vwoVariant,
    });
};

export const setVWOVariant = vwo => {
    StorageUtils.set({
        key: vwoVariant,
        value: vwo,
    });
};

export const getAllOfferedPrograms = () => {
    return StorageUtils.get({
        key: allOfferedPrograms,
    });
};

export const setAllOfferedPrograms = programs => {
    StorageUtils.set({
        key: allOfferedPrograms,
        value: programs,
    });
};

export const getRegistrationContext = () => {
    return StorageUtils.get({
        key: addApplicationPrefix(registrationContext),
    });
};

export const setRegistrationContext = context => {
    StorageUtils.set({
        key: addApplicationPrefix(registrationContext),
        value: context,
    });
};

export const getSsoContext = () => {
    return StorageUtils.get({
        key: addApplicationPrefix(ssoContext),
    });
};

export const setSsoContext = context => {
    StorageUtils.set({
        key: addApplicationPrefix(ssoContext),
        value: context,
    });
};

export const getPartner = () => {
    return StorageUtils.get({
        key: partner,
    });
};

export const setPartner = name => {
    StorageUtils.set({
        key: partner,
        value: name,
    });
};

export const getIsEmailQuestionAsked = () => {
    return StorageUtils.get({
        key: isEmailQuestionAsked,
    });
};

export const setIsEmailQuestionAsked = boolean => {
    StorageUtils.set({
        key: isEmailQuestionAsked,
        value: boolean,
    });
};

export const getCallBackUrl = () => {
    const savedUrl = StorageUtils.get({
        key: callBackUrl,
    });

    if (savedUrl !== null) {
        return decodeURIComponent(savedUrl);
    } else {
        return process.env.ONEAPP_LOGIN_URL;
    }
};

export const setCallBackUrl = url => {
    StorageUtils.set({
        key: callBackUrl,
        value: encodeURIComponent(url),
    });
};

export const getSavedAddress = () => {
    return StorageUtils.get({
        key: savedAddress,
    });
};

export const setSavedAddress = addressObj => {
    StorageUtils.set({
        key: savedAddress,
        value: addressObj,
    });
};

export const getTitleFromGRPC = () => {
    return StorageUtils.get({
        key: grpcTitle,
    });
};

export const setTitleFromGRPC = title => {
    StorageUtils.set({
        key: grpcTitle,
        value: title,
    });
};

export const getDisplayTitle = () => {
    return StorageUtils.get({
        key: displayTitle,
    });
};

export const setDisplayTitle = title => {
    StorageUtils.set({
        key: displayTitle,
        value: title,
    });
};

export const getStepNumber = () => {
    return StorageUtils.get({
        key: stepNumber,
    });
};

export const setStepNumber = step => {
    StorageUtils.set({
        key: stepNumber,
        value: step,
    });
};

export const setIsRedirectFromTeams = val => {
    StorageUtils.set({
        key: isRedirectFromTeams,
        value: val,
    });
};

export const getIsRedirectFromTeams = () => {
    return StorageUtils.get({
        key: isRedirectFromTeams,
    });
};

export const setUpsellProgram = program => {
    StorageUtils.set({
        key: upsellProgram,
        value: program,
    });
};

export const getUpsellProgram = () => {
    return StorageUtils.get({
        key: upsellProgram,
    });
};

export const setUserProgress = progress => {
    StorageUtils.set({
        key: userProgress,
        value: progress,
    });
};

export const getUserProgress = () => {
    return StorageUtils.get({
        key: userProgress,
    });
};

export const cleanSessionInfo = startOver => {
    if (startOver) {
        // Clean information for return user.
        StorageUtils.remove({key: addApplicationPrefix(userUUID)});
        StorageUtils.remove({key: addApplicationPrefix(correlationId)});
        StorageUtils.remove({key: addApplicationPrefix(isVisited)});
        StorageUtils.remove({key: addApplicationPrefix(userSessionExpires)});
        StorageUtils.remove({key: vwoVariant});
        StorageUtils.remove({key: addApplicationPrefix(registrationContext)});
        StorageUtils.remove({key: addApplicationPrefix(ssoContext)});
        StorageUtils.remove({key: applicationName});
        StorageUtils.remove({key: oneAppPortal});
    }

    StorageUtils.remove({key: userEmail});
    StorageUtils.remove({key: isEmailQuestionAsked});
    StorageUtils.remove({key: callBackUrl});
    StorageUtils.remove({key: savedAddress});
    StorageUtils.remove({key: grpcTitle});
    StorageUtils.remove({key: displayTitle});
    StorageUtils.remove({key: addApplicationPrefix(isAuthenticated)});
    StorageUtils.remove({key: addApplicationPrefix(grpcAccessToken)});
    StorageUtils.remove({key: addApplicationPrefix(grpcAccessTokenTimeStamp)});
    StorageUtils.remove({key: addApplicationPrefix(teladocSdkToken)});
    StorageUtils.remove({key: addApplicationPrefix(restApiAccessToken)});
    StorageUtils.remove({key: addApplicationPrefix(restApiRefreshToken)});
    StorageUtils.remove({key: addApplicationPrefix(restApiAccessCode)});
    StorageUtils.remove({key: addApplicationPrefix(regCode)});
    StorageUtils.remove({key: addApplicationPrefix(stepUrls)});
    StorageUtils.remove({key: addApplicationPrefix(stepIndex)});
    StorageUtils.remove({key: addApplicationPrefix(stepLoader)});
    StorageUtils.remove({key: addApplicationPrefix(stepNumber)});
    StorageUtils.remove({key: clientFriendlyName});
    StorageUtils.remove({key: allOfferedPrograms});
    StorageUtils.remove({key: partner});
    StorageUtils.remove({key: isRedirectFromTeams});
    StorageUtils.remove({key: upsellProgram});
    StorageUtils.remove({key: userProgress});
};

export const redirectToMemberPortal = () => {
    UserAPI.getAccessCode().then(response => {
        const accessCode = response.data?.code || '';
        const locale = getStoredLang() || 'en-US';

        window.location.href = `${process.env.MEMBER_PORTAL_URL}${accessCode}&locale=${locale}`;
    });

    cleanSessionInfo(true);
};

// for medopt usage
export const getOneAppUrl = () => {
    if (process.env.APP_ENV === 'production') {
        return `https://${Arg('returnSubDomain')}.teladoc.com${Arg(
            'returnPath'
        )}`;
    } else {
        return `https://${Arg('returnSubDomain')}${Arg('returnPath')}`;
    }
};

// for medopt usage
export const redirectToOneAppHome = () => {
    const url = getOneAppUrl();

    cleanSessionInfo(true);

    window.location.href = url;
};

// for medopt usage
export const redirectToMemberPortalOrOneAppHomePage = isOneApp => {
    return isOneApp ? redirectToOneAppHome() : redirectToMemberPortal();
};

const redirectToLivongoCoach = () => {
    UserAPI.getAccessCode().then(response => {
        const accessCode = response.data?.code || '';
        const locale = getStoredLang() || 'en-US';

        window.location.href = `${process.env.COACH_URL}?referrer=internal&access_code=${accessCode}&locale=${locale}`;
    });

    cleanSessionInfo(true);
};

const redirectToOneAppCoach = () => {
    const url = getOneAppUrl();

    window.location.href = `${url}condition-management/coaching`;
};

export const redirectToCoach = isOneApp => {
    isOneApp ? redirectToOneAppCoach() : redirectToLivongoCoach();
};

export const redirectToDownloadApps = isOneApp => {
    cleanSessionInfo(true);
    // TODO identify a static link for OneApp mobile app link
    window.location.href = isOneApp ? oneAppMobileAppLink : smsIframe;
};

export const redirectToTeams = () => {
    const {teamsDeeplinkUrl} = Config;

    window.location.href = teamsDeeplinkUrl.replace(
        'TEAMS_APP_ID',
        process.env.TEAMS_APP_ID
    );
};

export const getMelissaAddressData = value => {
    const result = fetch(
        `${process.env.MELISSA_ADDRESS_MIDDLEWARE_URL}` +
            `/v1/address-lookup?line1=${value}&maxrecords=30`
    )
        .then(data => data.json())
        .catch(error => devConsoleLog('Bad Request', error));

    return result;
};

/**
 * Build the pattern regex and the example to display in the UI from the gRPC pattern tokens
 * @param {Object} patternTokens the pattern tokens, as it defined in the gRPC enrollment service
 *  https://github.com/livongo/protos/blob/integration/src/livongo/protobuf/grpc/external/enrollment/common/pattern.proto
 * @t {Object} i18n translation object
 * @tKeys {Object} the translation keys needed to build the label dynamically. These keys should exist in the translation json files
 *  • range: "{{min}} to {{max}} $t(validation.character_plural)"
 *  • minimum: "a minimum of {{count}} $t(validation.character, {{count}})"
 *  • maximum: "a minimum of {{count}} $t(validation.number, {{count}})"
 *  • valueWithCharacter: "{{count}} $t(validation.character, {{count}})"
 *  • prefix: prefix
 *  • chunk1Prefix: "starting with"
 *  • chunk2Prefix: "followed by"
 *  • chunkLast: "(example: {{example}})"
 *  • error: "Please double check the format (ex: {{example}})."
 * @returns {Object} contains several parameters:
 *  label {String} the label based on the pattern
 *  pattern {String} the regex pattern
 *  example {String} an example for the pattern
 *  errorMsg {String} the error message
 */
export const getDynamicValidationParams = (patternTokens, t, tKeys) => {
    let label = '';
    let regex = '';
    let example = '';
    let errorMsg = '';
    let chunkCounter = 0;

    if (t && tKeys?.prefix) label = t(tKeys.prefix);

    patternTokens?.forEach(patternToken => {
        const {
            pb_const: strConst,
            anyCharacter: charAny,
            characterClass: charClass,
        } = patternToken || {};
        let chunk = '';
        const charTypeValues = {
            character: 'character',
            number: 'number',
            letter: 'letter',
            letterOrNumber: 'letterOrNumber',
        };

        chunkCounter++;

        if (chunkCounter === 1) {
            if (t && tKeys?.chunk1Prefix) {
                chunk = `, ${t(tKeys.chunk1Prefix)}`;
            }
        } else if (chunkCounter >= 2) {
            if (t && tKeys?.chunk2Prefix) {
                chunk = `, ${t(tKeys.chunk2Prefix)}`;
            }
        }

        // RegExp constant
        // Matches a fixed value
        if (strConst) {
            label += `${chunk} ${strConst}`;
            example += strConst;
            strConst.split('').forEach(charConst => {
                // if the character is a letter, add lower and uppercase to the regex
                if (/[a-zA-Z]/.test(charConst)) {
                    regex += `(${charConst.toLowerCase()}|${charConst.toUpperCase()})`;
                }
                // otherwise add the constant as is
                else {
                    regex += charConst;
                }
            });
        } else if (charAny || charClass) {
            const minLength = charClass?.minLength?.value;
            const maxLength = charClass?.maxLength?.value;
            let charType = '';

            // Matches a sequence of arbitrary characters
            // Equivalent to `[-.#_a-zA-Z0-9]{min_length,max_length}`
            if (charAny) {
                charType = charTypeValues.character;
                example += charAny?.example?.value;
                regex += '[-.#_a-zA-Z0-9]';
            }
            // Matches a sequence of characters in one of the common character sets (DIGIT or APHA).
            // Equivalent to `[{value}]{min_length,max_length}`
            // Can also be `[{DIGIT}{ALPHA}]{min_length,max_length}`
            else if (charClass) {
                let partial = '';
                const characterTypes = charClass?.propertiesList;

                example += charClass?.example?.value;
                characterTypes?.forEach(characterType => {
                    switch (characterType) {
                        case 1: // DIGIT
                            // if the first characterType is a letter, and the second is a digit, use charTypeValues.letterOrNumber for the label
                            if (charType === charTypeValues.letter) {
                                charType = charTypeValues.letterOrNumber;
                            } else {
                                charType = charTypeValues.number;
                            }
                            partial += '0-9';
                            break;
                        case 2: // ALPHA = ALPHABETIC
                            // if the first characterType is a number, and the second is a letter, use charTypeValues.letterOrNumber for the label
                            if (charType === charTypeValues.number) {
                                charType = charTypeValues.letterOrNumber;
                            } else {
                                charType = charTypeValues.letter;
                            }
                            partial += 'a-zA-Z';
                            break;
                    }
                });

                if (partial) regex += `[${partial}]`;
            }

            // RegExp quantifier
            // this part is duplicating BE logic to make sure FE has the same handling of edge cases
            if (!minLength && !maxLength) {
                regex += '*';
            } else if (minLength && !maxLength) {
                if (t && tKeys.minimum) {
                    chunk += ` ${t(tKeys.minimum, {
                        count: minLength,
                        context: charType,
                    })}`;
                }
                label += chunk;
                regex += `{${minLength},}`;
            } else if (!minLength && maxLength) {
                if (t && tKeys.maximum) {
                    chunk += ` ${t(tKeys.maximum, {
                        count: maxLength,
                        context: charType,
                    })}`;
                }
                label += chunk;
                regex += `{0,${maxLength}}`;
            } else if (minLength && maxLength && minLength === maxLength) {
                if (t && tKeys.valueWithCharacter) {
                    chunk += ` ${t(tKeys.valueWithCharacter, {
                        count: minLength,
                        context: charType,
                    })}`;
                }
                label += chunk;
                regex += `{${minLength}}`;
            } else if (minLength && maxLength) {
                if (t && tKeys?.range) {
                    chunk += ` ${t(tKeys.range, {
                        min: minLength,
                        max: maxLength,
                        context: charType,
                    })}`;
                }
                label += chunk;
                regex += `{${minLength},${maxLength}}`;
            } else {
                label += chunk;
            }
        }
    });

    if (example && t && tKeys?.chunkLast) {
        label += ` ${t(tKeys.chunkLast, {example})}`;
    }
    const pattern = regex ? `^${regex}$` : '';

    if (t && tKeys.error && example) {
        errorMsg = t(tKeys.error, {
            example,
        });
    }

    return {label, pattern, placeholder: example, errorMsg};
};

export const checkU13 = userDOB => {
    // oldDate is current date 13 years ago
    const oldDate = new Date(
        new Date().setFullYear(new Date().getFullYear() - 13)
    );
    const userDate = new Date(userDOB);

    return isBefore(oldDate, userDate);
};

export const generateResponseForSsoWarmWelcome = response => {
    const tAndCQuestion = {
        questionId: {
            id: 86,
            version: 1,
        },
        required: true,
        validationErrorsList: [],
        dataType: {
            boolField: {},
        },
    };

    if (response.currentStep) {
        if (response.currentStep.questionsToAsk) {
            response.currentStep.questionsToAsk.questionsList = {
                ...response.currentStep.questionsToAsk.questionsList,
                tAndCQuestion,
            };
        } else {
            response.currentStep.questionsToAsk = {
                questionsList: [tAndCQuestion],
            };
        }
    } else if (response.previousStep) {
        if (response.previousStep.questionsToAsk) {
            response.previousStep.questionsToAsk.questionsList = {
                ...response.previousStep.questionsToAsk.questionsList,
                tAndCQuestion,
            };
        } else {
            response.previousStep.questionsToAsk = {
                questionsList: [tAndCQuestion],
            };
        }
    }

    return response;
};

export const getCountry = () => {
    return process.env.COUNTRY_CODE || 'US';
};

export const getWebSdkApplicationConfiguration = (isOneApp, baseUrl) => {
    const config = {
        baseUrl,
        apiToken: process.env.WEBSDK_API_TOKEN,
        googleApiKey: process.env.WEBSDK_API_GOOGLE_KEY,
        isPulse: true,
        bundleIdentifier: isOneApp
            ? Config.oneAppBundleIdentifier
            : Config.livongoBundleIdentifier,
    };

    if (isOneApp) {
        config.authorizedMemberId = getTdocMemberId();
    }

    return config;
};

export const addTermsAndCondToForm = (formData, currNodeTitleId) => {
    if (
        currNodeTitleId === askGuardianInfo ||
        currNodeTitleId === askU18GuardianInfo
    ) {
        if (
            formData['GUARDIAN_FIRST_NAME_-_93'] &&
            formData['GUARDIAN_EMAIL_-_94'] &&
            formData['ACKNOWLEDGED_CHILD_PRIVACY_POLICY_-_132'] === 'true'
        ) {
            formData['TERMS_AND_CONDITIONS_-_86'] = 'true';
        }
    }
};

export const removeSpecificQuestionsIfNotInForm = (
    formData,
    questionList,
    nodeTitleId,
    ...questionIDs
) => {
    addTermsAndCondToForm(formData, nodeTitleId);

    const questionIdsCurrentlyInForm = Object.keys(formData).map(
        questionWithId => {
            return Number(questionWithId.split('_-_')[1]);
        }
    );

    return questionList.filter(question => {
        const isQuestionId = qId => qId === question.questionId.id;

        // only check if the specific questions in Form
        if (questionIDs.some(isQuestionId)) {
            return questionIdsCurrentlyInForm.includes(question.questionId.id);
        } else {
            return true;
        }
    });
};

export const getSplicedDomainName = () => {
    return window.location.hostname?.split('.')?.splice(-2)?.join('.');
};

// check if token timestamp is older than 24hrs
export const checkIsTokenExpired = () => {
    const currentDateTime = Date.now();
    const tokenDateTime = getGrpcAccessTokenTimeStamp();
    const hoursDifference = currentDateTime - tokenDateTime / (1000 * 60 * 60);

    return hoursDifference > 24;
};
