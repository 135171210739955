import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {useTranslation, Trans} from 'react-i18next';
import Button from '@teladoc/pulse/ui/Button';
import Image from '@teladoc/pulse/ui/Image';
import {navigationBtnDisable} from 'store/navigationButton/navigationBtnDisable-actions';
import useMessage from 'hook/useMessage';
import UserAPI from 'api/rest/user-api';
import {
    redirectToMemberPortal,
    getRegistrationContext,
    getUpsellProgram,
    getCallBackUrl,
    cleanSessionInfo,
    getRestApiAccessToken,
} from 'utilities/utils';
import {programs} from 'constants/app';
import Config from 'config';
import css from './UpsellConfirmation.scss';

const {BEHAVIORAL_HEALTH, DIABETES_DEVICEFLEX} = programs;
const {
    bhSSOUrl,
    memberPortalURL,
    memberSupportPhoneNumber,
    oneAppMemberSupportPhoneNumber,
} = Config;

const UpsellConfirmation = ({contentId, sectionInfo}) => {
    const {t} = useTranslation('content');
    const dispatch = useDispatch();
    const [userInfo, setUserInfo] = useState({});
    const {program: selectedPrograms} = getUpsellProgram();
    const {client} = getRegistrationContext() ?? {};
    const isOneApp = client === Config.client.oneapp;
    const {showQuestionsFetchErrorMessage} = useMessage();

    const noWelcomeKits =
        selectedPrograms === BEHAVIORAL_HEALTH ||
        selectedPrograms === DIABETES_DEVICEFLEX;

    const restAuthToken = getRestApiAccessToken();

    const bearer = {
        headers: {
            Authorization: `Bearer ${restAuthToken}`,
        },
    };

    const getUserInfo = evt => {
        UserAPI.getUserInfo(bearer)
            .then(response => {
                setUserInfo(response);
            })
            .catch(response => showQuestionsFetchErrorMessage());
    };

    const welcomeKitMapping = {
        DIABETES: 'bg1000_kit_esn66a',
        PREDIABETES: 'wm1500_kit_cndvkv',
        HYPERTENSION: 'ht900_kit_livtwz',
        WEIGHT_MANAGEMENT: 'wm1500_kit_cndvkv',
        CHRONIC_KIDNEY_DISEASE: 'ht900_kit_livtwz',
        HEART_FAILURE: 'ht900_kit_livtwz',
    };

    const oneappWelcomeKitMapping = {
        DIABETES: 'BG300-oneapp_tpaqnc',
        PREDIABETES: 'WM1500-oneapp_jodh78',
        HYPERTENSION: 'HT900-oneapp_k8wa3p',
        WEIGHT_MANAGEMENT: 'WM1500-oneapp_jodh78',
        CHRONIC_KIDNEY_DISEASE: 'HT900-oneapp_k8wa3p',
        HEART_FAILURE: 'HT900-oneapp_k8wa3p',
    };

    const noWelcomeKitsMapping = {
        BEHAVIORAL_HEALTH: 'Program_BH_bhjijc',
        DIABETES_DEVICEFLEX: 'Program_DBT_cbjk89',
    };

    const redirectToBH = () => {
        window.open(`${bhSSOUrl}?n=t`, 'bhWnd');
    };

    const redirectToOneApp = () => {
        const redirectUrl = getCallBackUrl();

        cleanSessionInfo(true);
        window.location.href = redirectUrl;
    };

    /* NavigationButton must be disabled in this component */
    useEffect(() => {
        getUserInfo();

        let obj = {backBtn: true, nextBtn: true};

        dispatch(navigationBtnDisable(obj));

        return () => {
            obj = {backBtn: false, nextBtn: false};

            dispatch(navigationBtnDisable(obj)); // default value
        };
        // eslint-disable-next-line
    }, []);

    return (
        <div className={css.root}>
            {!noWelcomeKits && (
                <h1>
                    {isOneApp
                        ? t('UpsellConfirmation.shippingSection.oneAppTitle')
                        : t('UpsellConfirmation.shippingSection.title')}
                </h1>
            )}
            {noWelcomeKits && (
                <h1>{t('UpsellConfirmation.getStartedSection.title')}</h1>
            )}
            {!noWelcomeKits && (
                <p>
                    {isOneApp
                        ? t(
                              'UpsellConfirmation.shippingSection.oneAppDescription'
                          )
                        : t('UpsellConfirmation.shippingSection.description')}
                </p>
            )}
            {!isOneApp && !noWelcomeKits && (
                <section className={css.shippingContainer}>
                    <h2>{t('UpsellConfirmation.shippingSection.subTitle')}</h2>
                    <div className={css.programItem}>
                        <Image
                            classNameImage={css.imageContainer}
                            cloudinaryImageId={`registration/components/questions/address/${welcomeKitMapping[selectedPrograms]}`}
                            width={200}
                            height={120}
                            alt={t(
                                `UpsellConfirmation.toolkit.${selectedPrograms}.title`
                            )}
                        />
                        <div className={css.imgAdj}>
                            {t(
                                `UpsellConfirmation.toolkit.${selectedPrograms}.description`
                            )}
                        </div>
                    </div>
                    <div className={css.address}>
                        <p>
                            {t(
                                'UpsellConfirmation.shippingSection.address.title'
                            )}
                        </p>
                        <p>{userInfo?.recipient}</p>
                        <p>{userInfo?.addressLine1}</p>
                        <p>{userInfo?.lastLine}</p>
                    </div>
                    <div className={css.shippingNotes}>
                        <Trans
                            i18nKey="content:UpsellConfirmation.shippingSection.address.notes"
                            components={[
                                // eslint-disable-next-line
                                <a href={`tel:${memberSupportPhoneNumber}`} />,
                            ]}
                        />
                    </div>
                </section>
            )}
            {isOneApp && !noWelcomeKits && (
                <section className={css.shippingContainer}>
                    <div className={css.oneAppImageContainer}>
                        <Image
                            cloudinaryImageId={`registration/components/questions/address/${oneappWelcomeKitMapping[selectedPrograms]}`}
                            width={64}
                            height={64}
                            alt="BG300 device"
                            showLoader={false}
                        />
                        {t(
                            `UpsellConfirmation.toolkit.${selectedPrograms}.oneappDescription`
                        )}
                    </div>
                    <div className={css.oneappAddress}>
                        <p>
                            {t(
                                'UpsellConfirmation.shippingSection.address.oneAppTitle'
                            )}
                        </p>
                        <p>{userInfo?.recipient}</p>
                        <p>{userInfo?.addressLine1}</p>
                        <p>{userInfo?.lastLine}</p>
                    </div>
                    <div className={css.legalTextBlock}>
                        <Trans
                            i18nKey="content:UpsellConfirmation.shippingSection.address.oneAppNotes"
                            components={[
                                // eslint-disable-next-line
                                <a
                                    href={`tel:${oneAppMemberSupportPhoneNumber}`}
                                />,
                            ]}
                        />
                    </div>
                    <Button
                        onClick={redirectToOneApp}
                        className={css.homeButton}
                        variant="primary"
                    >
                        {t(
                            'UpsellConfirmation.shippingSection.address.oneAppHomeButton'
                        )}
                    </Button>
                </section>
            )}

            {noWelcomeKits && (
                <section className={css.homeItem}>
                    <h3>
                        {t(
                            `UpsellConfirmation.getStartedSection.${selectedPrograms}.title`
                        )}
                    </h3>
                    <div>
                        <Image
                            classNameImage={css.imageContainer}
                            cloudinaryImageId={`registration/components/questions/program/pulse/${noWelcomeKitsMapping[selectedPrograms]}`}
                            width={200}
                            height={120}
                            alt={t(
                                `UpsellConfirmation.getStartedSection.${selectedPrograms}.title`
                            )}
                        />
                        <div className={css.imgAdj}>
                            {t(
                                `UpsellConfirmation.getStartedSection.${selectedPrograms}.description`
                            )}
                        </div>
                    </div>
                    {selectedPrograms === BEHAVIORAL_HEALTH && (
                        <Button
                            onClick={redirectToBH}
                            className={css.homeButton}
                            variant="primary"
                            size="small"
                        >
                            {t(
                                `UpsellConfirmation.getStartedSection.${selectedPrograms}.bhButton`
                            )}
                        </Button>
                    )}
                </section>
            )}

            {!isOneApp && (
                <section className={css.homeItem}>
                    <h1>{t('UpsellConfirmation.nextSection.title')}</h1>
                    <p>
                        <Trans
                            i18nKey="content:UpsellConfirmation.nextSection.description"
                            values={{
                                memberPortalURL,
                            }}
                            components={[
                                /* eslint-disable-next-line*/
                                <a href="#" onClick={redirectToMemberPortal}>
                                    {memberPortalURL}
                                </a>,
                            ]}
                        />
                    </p>

                    <Button
                        onClick={redirectToMemberPortal}
                        className={css.homeButton}
                        variant="primary"
                    >
                        {t('UpsellConfirmation.nextSection.btnText')}
                    </Button>
                </section>
            )}
        </div>
    );
};

UpsellConfirmation.propTypes = {
    contentId: PropTypes.string.isRequired,
    sectionInfo: PropTypes.object.isRequired,
};

export default UpsellConfirmation;
