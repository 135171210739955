import {useTranslation, Trans} from 'react-i18next';
import Button from '@teladoc/pulse/ui/Button';
import {cleanSessionInfo} from 'utilities/utils';
import Config from 'config';
import css from './LinkedAccount.scss';

const {oneAppMemberSupportPhoneNumber} = Config;

const LinkedAccount = () => {
    // This Linked Account route mainly for oneapp deactivated member
    const {t} = useTranslation('content');

    const handleClick = () => {
        const redirectUrl = process.env.ONEAPP_LOGIN_URL ?? Config.oneAppUrl;

        cleanSessionInfo(true);
        window.location.href = redirectUrl;
    };

    return (
        <div className={css.container}>
            <div className={css.root}>
                <h1 className={css.h1}>{t('LinkedAccount.title')}</h1>
                <div className={css.p}>
                    <Trans
                        i18nKey="content:LinkedAccount.paragraph"
                        components={[
                            /* eslint-disable react/jsx-key */
                            <a href={`tel:${oneAppMemberSupportPhoneNumber}`}>
                                {oneAppMemberSupportPhoneNumber}
                            </a>,
                        ]}
                    />
                </div>
                <Button
                    className={css.oneAppSkipButton}
                    variant="primary"
                    onClick={handleClick}
                >
                    {t('LinkedAccount.btnText')}
                </Button>
            </div>
        </div>
    );
};

export default LinkedAccount;
