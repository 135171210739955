// this is hardcoded response to let biometric alerts to do the mapping
export const biometricAlertsNavNextConfig = {
    HERE_IS_HOW: {
        onboardingComplete: false,
        nextStep: {
            context: {
                sectionId: 1,
                answersList: [],
                stepNumber: {
                    value: 1,
                },
                numberOfSteps: {
                    value: 5,
                },
            },
            contentId: 'HERE_IS_HOW',
            triggerId: '',
        },
    },

    SMART_PHONE: {
        onboardingComplete: false,
        nextStep: {
            context: {
                sectionId: 1,
                answersList: [],
                stepNumber: {
                    value: 2,
                },
                numberOfSteps: {
                    value: 5,
                },
            },
            questionsToAsk: {
                questionsList: [
                    {
                        questionId: {
                            id: 1,
                        },
                        required: true,
                        dataType: {
                            boolField: {},
                        },
                    },
                ],
            },
            contentId: '',
            triggerId: '',
        },
    },

    QUALIFY: {
        onboardingComplete: false,
        nextStep: {
            context: {
                sectionId: 1,
                answersList: [],
                stepNumber: {
                    value: 3,
                },
                numberOfSteps: {
                    value: 5,
                },
            },
            questionsToAsk: {
                questionsList: [
                    {
                        questionId: {
                            id: 2,
                        },
                        required: true,
                        dataType: {
                            multiSelectEnum: {
                                answerCodesList: [
                                    'TYPE_1_DIABETES',
                                    'USING_MEALTIME_INSULIN',
                                    'KIDNEY_DISEASE',
                                    'CANCER_OR_CHEMOTHERAPY',
                                    'HEART_OR_LIVER_FAILURE',
                                    'PREGNANT_BREASTFEEDING_PURSUING',
                                    'ORGAN_TRANSPLANT',
                                    'HOSPICE_OR_PALLIATIVE_CARE',
                                    'NONE_OF_THESE_APPLY',
                                ],
                            },
                        },
                    },
                ],
            },
            contentId: '',
            triggerId: '',
        },
    },

    GOOD_MATCH: {
        onboardingComplete: false,
        nextStep: {
            context: {
                sectionId: 1,
                answersList: [],
                stepNumber: {
                    value: 5,
                },
                numberOfSteps: {
                    value: 5,
                },
            },
            contentId: 'VALUE_POINTS',
            triggerId: '',
        },
    },

    RAV_PLACEHOLDER: {
        onboardingComplete: false,
        nextStep: {
            context: {
                sectionId: 5,
                answersList: [],
                stepNumber: {
                    value: 1,
                },
                numberOfSteps: {
                    value: 1,
                },
            },
            contentId: '',
            triggerId: 'WEB_SDK',
        },
    },

    GET_THE_APP: {
        onboardingComplete: false,
        nextStep: {
            context: {
                sectionId: 6,
                answersList: [],
                stepNumber: {
                    value: 1,
                },
                numberOfSteps: {
                    value: 1,
                },
            },
            contentId: 'GET_THE_APP',
            triggerId: '',
        },
    },

    INELIGIBLE: {
        onboardingComplete: false,
        nextStep: {
            context: {
                sectionId: 1,
                answersList: [],
                stepNumber: {
                    value: 4,
                },
                numberOfSteps: {
                    value: 5,
                },
            },
            contentId: 'INELIGIBLE',
            triggerId: '',
        },
    },
};

export const biometricAlertsNavBackConfig = {
    HERE_IS_HOW: {
        onboardingComplete: false,
        previousStep: {
            context: {
                sectionId: 1,
                answersList: [
                    {
                        contentAck: 'HERE_IS_HOW',
                        triggerAck: '',
                    },
                ],
                stepNumber: {
                    value: 1,
                },
                numberOfSteps: {
                    value: 5,
                },
            },
            contentId: 'HERE_IS_HOW',
            triggerId: '',
        },
    },

    SMART_PHONE: {
        onboardingComplete: false,
        previousStep: {
            context: {
                sectionId: 1,
                answersList: [
                    {
                        questionId: {
                            id: 1,
                        },
                        contentAck: '',
                        triggerAck: '',
                        submittedAnswer: {
                            boolField: {
                                answer: {
                                    value: true,
                                },
                            },
                        },
                    },
                ],
                stepNumber: {
                    value: 2,
                },
                numberOfSteps: {
                    value: 5,
                },
            },
            questionsToAsk: {
                questionsList: [
                    {
                        questionId: {
                            id: 1,
                        },
                        required: true,
                        dataType: {
                            boolField: {},
                        },
                    },
                ],
            },
            contentId: '',
            triggerId: '',
        },
    },

    QUALIFY: {
        onboardingComplete: false,
        previousStep: {
            context: {
                sectionId: 1,
                answersList: [
                    {
                        questionId: {
                            id: 2,
                        },
                        contentAck: '',
                        triggerAck: '',
                        submittedAnswer: {
                            multiSelectEnum: {
                                answerCodesList: ['NONE_OF_THESE_APPLY'],
                            },
                        },
                    },
                ],
                stepNumber: {
                    value: 3,
                },
                numberOfSteps: {
                    value: 5,
                },
            },
            questionsToAsk: {
                questionsList: [
                    {
                        questionId: {
                            id: 2,
                        },
                        required: true,
                        dataType: {
                            multiSelectEnum: {
                                answerCodesList: [
                                    'TYPE_1_DIABETES',
                                    'USING_MEALTIME_INSULIN',
                                    'KIDNEY_DISEASE',
                                    'CANCER_OR_CHEMOTHERAPY',
                                    'HEART_OR_LIVER_FAILURE',
                                    'PREGNANT_BREASTFEEDING_PURSUING',
                                    'ORGAN_TRANSPLANT',
                                    'HOSPICE_OR_PALLIATIVE_CARE',
                                    'NONE_OF_THESE_APPLY',
                                ],
                            },
                        },
                    },
                ],
            },
            contentId: '',
            triggerId: '',
        },
    },

    GOOD_MATCH: {
        onboardingComplete: false,
        previousStep: {
            context: {
                sectionId: 1,
                answersList: [
                    {
                        contentAck: 'VALUE_POINTS',
                        triggerAck: '',
                    },
                ],
                stepNumber: {
                    value: 5,
                },
                numberOfSteps: {
                    value: 5,
                },
            },
            contentId: 'VALUE_POINTS',
            triggerId: '',
        },
    },

    RAV_PLACEHOLDER: {
        onboardingComplete: false,
        previousStep: {
            context: {
                sectionId: 5,
                answersList: [],
                stepNumber: {
                    value: 1,
                },
                numberOfSteps: {
                    value: 1,
                },
            },
            contentId: '',
            triggerId: 'WEB_SDK',
        },
    },

    GET_THE_APP: {
        onboardingComplete: false,
        previousStep: {
            context: {
                sectionId: 6,
                answersList: [],
                stepNumber: {
                    value: 1,
                },
                numberOfSteps: {
                    value: 1,
                },
            },
            contentId: 'GET_THE_APP',
            triggerId: '',
        },
    },

    INELIGIBLE: {
        onboardingComplete: false,
        previousStep: {
            context: {
                sectionId: 1,
                answersList: [],
                stepNumber: {
                    value: 4,
                },
                numberOfSteps: {
                    value: 5,
                },
            },
            contentId: 'INELIGIBLE',
            triggerId: '',
        },
    },
};
