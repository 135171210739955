import {useEffect, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {useTranslation, Trans} from 'react-i18next';
import IconCheckCircleDefault from '@teladoc/pulse/icons/check-circle-default.svg';
import IconClockDefault from '@teladoc/pulse/icons/clock-default.svg';
import Button from '@teladoc/pulse/ui/Button';
import Loader from '@teladoc/pulse/ui/Loader';
import useMessage from 'hook/useMessage';
import {navigationBtnDisable} from 'store/navigationButton/navigationBtnDisable-actions';
import {
    getCallBackUrl,
    getRestApiAccessCode,
    cleanSessionInfo,
} from 'utilities/utils';
import {programs} from 'constants/app';
import {QuestionId} from 'api/protobuf/enrollment/protoTypes';
import {createAndAuthenticateAccount} from 'routes/registration/Registration';
import Config from 'config';
import css from './OneAppConfirmation.scss';

const {BEHAVIORAL_HEALTH} = programs;
const {
    oneAppMemberSupportPhoneNumber,
    oneAppMemberSupportFormattedPhoneNumber,
} = Config;

const OneAppConfirmation = ({
    contentId,
    sectionInfo,
    isLoading,
    setLoading,
}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation('content');
    const isNoRecruitableMatch = contentId === 'ConfirmationNoRecruitableMatch';
    const {showNotFoundRedirectUrl} = useMessage();
    const answersWithinContext = sectionInfo.context?.answersList || [];
    const programAnswer = answersWithinContext.find(
        answer => answer.questionId?.id === QuestionId.Values.PROGRAM
    );
    const oneAppSelectedPrograms = useMemo(
        () => programAnswer?.value?.multiSelectEnum?.enumCodeList || [],
        [programAnswer]
    );
    const readyToLoadForBHOnly =
        oneAppSelectedPrograms.includes(BEHAVIORAL_HEALTH) &&
        oneAppSelectedPrograms.length === 1;

    const redirectToOneApp = bhOnly => {
        let redirectUrl = getCallBackUrl();
        // eslint-disable-next-line node/prefer-global/url
        const url = new URL(redirectUrl);

        if (bhOnly && !url.pathname.includes('mental-health')) {
            redirectUrl = `${url.origin}/mental-health/interstitial${url.search}`;
        }

        cleanSessionInfo();
        window.location.href = redirectUrl;
    };

    const authCode = getRestApiAccessCode();

    /* NavigationButton must be disabled in this component */
    /* When readyToLoadForBHOnly is true, SSO to myStrength  */
    useEffect(() => {
        if (readyToLoadForBHOnly) {
            const callCreateAccount = async () => {
                if (authCode === null) {
                    setLoading(true);
                    await createAndAuthenticateAccount();
                }

                if (getCallBackUrl()) {
                    setLoading(true);
                    redirectToOneApp(true);
                } else {
                    showNotFoundRedirectUrl();
                }
            };

            callCreateAccount();
        }

        let obj = {backBtn: true, nextBtn: true};

        dispatch(navigationBtnDisable(obj));

        return () => {
            obj = {backBtn: false, nextBtn: false};

            dispatch(navigationBtnDisable(obj)); // default value
        };
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {isLoading && <Loader className="Loader-module-root" />}
            {!isLoading && (
                <>
                    {isNoRecruitableMatch ? (
                        <IconClockDefault className={css.iconClock} />
                    ) : (
                        <IconCheckCircleDefault className={css.iconCheck} />
                    )}
                    <h1 className={css.h1}>
                        {isNoRecruitableMatch
                            ? t('ConfirmationNoRecruitableMatch.title')
                            : t('OneAppConfirmation.title')}
                    </h1>

                    {isNoRecruitableMatch && (
                        <p className={css.p}>
                            {t('ConfirmationNoRecruitableMatch.paragraph')}
                        </p>
                    )}
                    {!isNoRecruitableMatch && (
                        <p className={css.p}>
                            <Trans
                                i18nKey="content:OneAppConfirmation.paragraph"
                                values={{
                                    oneAppMemberSupportFormattedPhoneNumber,
                                }}
                                components={[
                                    /* eslint-disable react/jsx-key */
                                    <a
                                        href={`tel:${oneAppMemberSupportPhoneNumber}`}
                                    >
                                        {oneAppMemberSupportPhoneNumber}
                                    </a>,
                                    /* eslint-disable react/jsx-key */
                                ]}
                            />
                        </p>
                    )}

                    <Button
                        className={css.button}
                        variant="primary"
                        onClick={() => {
                            redirectToOneApp(false);
                        }}
                    >
                        {isNoRecruitableMatch
                            ? t('ConfirmationNoRecruitableMatch.btnText')
                            : t('OneAppConfirmation.btnText')}
                    </Button>
                </>
            )}
        </>
    );
};

OneAppConfirmation.propTypes = {
    contentId: PropTypes.string.isRequired,
    sectionInfo: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    setLoading: PropTypes.func,
};

export default OneAppConfirmation;
