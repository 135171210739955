import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation, Trans} from 'react-i18next';
import Button from '@teladoc/pulse/ui/Button';
import {navigationBtnDisable} from 'store/navigationButton/navigationBtnDisable-actions';
import Config from 'config';
import {getCallBackUrl, cleanSessionInfo} from 'utilities/utils';
import css from './OneAppEnrollmentBlocked.scss';

const {oneAppMemberSupportPhoneNumber} = Config;

const ReEnrollmentBlocked = () => {
    const {t} = useTranslation('content');
    const dispatch = useDispatch();
    const redirectToOneApp = () => {
        const redirectUrl = getCallBackUrl();

        cleanSessionInfo(true);
        window.location.href = redirectUrl;
    };

    /* NavigationButton must be disabled in this component */
    useEffect(() => {
        let obj = {backBtn: true, nextBtn: true};

        dispatch(navigationBtnDisable(obj));

        return () => {
            obj = {backBtn: false, nextBtn: false};

            dispatch(navigationBtnDisable(obj)); // default value
        };
        // eslint-disable-next-line
    }, []);

    return (
        <div className={css.root}>
            <h1 className={css.h1}>{t('ReEnrollmentBlocked.title')}</h1>

            <p className={css.p}>
                <Trans
                    i18nKey="content:ReEnrollmentBlocked.paragraph"
                    components={[
                        /* eslint-disable react/jsx-key */
                        <a href={`tel:${oneAppMemberSupportPhoneNumber}`}>
                            {oneAppMemberSupportPhoneNumber}
                        </a>,
                        /* eslint-enable react/jsx-key */
                    ]}
                />
            </p>

            <Button
                className={css.oneAppButton}
                variant="primary"
                onClick={redirectToOneApp}
            >
                {t('ReEnrollmentBlocked.btnText')}
            </Button>
        </div>
    );
};

export default ReEnrollmentBlocked;
