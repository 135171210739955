import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';
import TextInput from '@teladoc/pulse/ui/TextInput';
import Select from '@teladoc/pulse/ui/Select';
import Label from '@teladoc/pulse/ui/Label';
import Image from '@teladoc/pulse/ui/Image';
import Button from '@teladoc/pulse/ui/Button';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import StateSelect from '@teladoc/pulse/ui/StateSelect';
import ZipInput from '@teladoc/pulse/ui/ZipInput';
import {getQuestionNameById} from 'api/protobuf/enrollment/services';
import {QuestionId} from 'api/protobuf/enrollment/protoTypes';
import {questionConfig} from 'constants/questions';
import {programs as programsName} from 'constants/app';
import Config from 'config';
import {
    getFirstNonEmptyKey,
    getRegistrationContext,
    setSavedAddress,
    getSavedAddress,
} from 'utilities/utils';
import css from './Address.scss';

const {
    DIABETES,
    HYPERTENSION,
    WEIGHT_MANAGEMENT,
    PREDIABETES,
    CHRONIC_KIDNEY_DISEASE,
    HEART_FAILURE,
    ADV_WEIGHT_MANAGEMENT,
    ADV_PREDIABETES,
    COMPR_WEIGHT_CARE,
    COMPR_PREDIABETES_CARE,
} = programsName;

// TODO: Update subValue with BE return.
const AddressMilitary = ({
    id,
    name,
    question,
    questionValues,
    customValidationError,
    onChange,
    includeMilitary,
    includeUSTerritories,
    context,
    isAddressFromEditable,
    setIsAddressFromEditable,
    formRef,
}) => {
    const {t} = useTranslation('questions');
    const questions = question?.dataType?.compoundField?.questionsList || [];
    const questionIDValueHash = {};
    const questionIDDefaultValueHash = {};
    const {client} = getRegistrationContext() ?? {};
    const isOneApp = client === Config.client.oneapp;
    const answersWithinContext = context.answersList || [];
    const programAnswer = answersWithinContext.find(
        answer => answer.questionId?.id === QuestionId.Values.PROGRAM
    );
    const lastNameAnswer = answersWithinContext.find(
        answer => answer.questionId?.id === QuestionId.Values.LAST_NAME
    );
    const firstNameAnswer = answersWithinContext.find(
        answer => answer.questionId?.id === QuestionId.Values.FIRST_NAME
    );
    const selectedPrograms =
        programAnswer?.value?.multiSelectEnum?.enumCodeList || [];
    const lastName = lastNameAnswer?.value?.text || '';
    const firstName = firstNameAnswer?.value?.text || '';
    const isDiabetes = selectedPrograms.includes(DIABETES);
    const isHypertension = selectedPrograms.includes(HYPERTENSION);
    const isWeightManagement =
        selectedPrograms.includes(WEIGHT_MANAGEMENT) ||
        selectedPrograms.includes(ADV_WEIGHT_MANAGEMENT);
    const isPreDiabetes =
        selectedPrograms.includes(PREDIABETES) ||
        selectedPrograms.includes(ADV_PREDIABETES);
    const isComprehensiveCarePrograms =
        selectedPrograms.includes(COMPR_WEIGHT_CARE) ||
        selectedPrograms.includes(COMPR_PREDIABETES_CARE);
    const isChronicKidneyDisease = selectedPrograms.includes(
        CHRONIC_KIDNEY_DISEASE
    );
    const isHeartFailure = selectedPrograms.includes(HEART_FAILURE);
    let savedAnswer = getSavedAddress();
    let isAllRequiredQuestionAnswered = true;

    questions.forEach(que => {
        const questionName = getQuestionNameById(que.questionId.id);
        const questionTypedField = getFirstNonEmptyKey(que.dataType);
        const dataType = que.dataType[questionTypedField];

        questionIDValueHash[questionName] = que;

        // can handle TEXT_FIELD, PROBABLY BOOLEAN_FIELD, INTEGER_FIELD, FLOAT_FIELD, and SINGLE_SELECT_ENUM
        if (dataType.userInput) {
            questionIDDefaultValueHash[questionName] = dataType.userInput;
        } else if (dataType.previousAnswer) {
            questionIDDefaultValueHash[questionName] =
                dataType.previousAnswer.value;
        } else if (dataType.defaultAnswer) {
            questionIDDefaultValueHash[questionName] =
                dataType.defaultAnswer.value;
        } else if (dataType.previousAnswerCode) {
            questionIDDefaultValueHash[questionName] =
                dataType.previousAnswerCode.value;
        } else if (dataType.defaultAnswerCode) {
            questionIDDefaultValueHash[questionName] =
                dataType.defaultAnswerCode.value;
        } else {
            // fail into this else only if there is no any previousAnswer or defaultAnswer found
            // so if the question required to have answer, set AddressFrom to editable
            if (que.required && !savedAnswer) {
                setIsAddressFromEditable(true);
                isAllRequiredQuestionAnswered = false;
            }
        }
    });

    const [disableCancelButton, setDisableCancelButton] = useState(
        !isAllRequiredQuestionAnswered
    );

    if (savedAnswer === null) {
        savedAnswer = questionIDDefaultValueHash;
    }

    for (const key in questionValues) {
        if (questionValues[key]) {
            questionIDDefaultValueHash[key] = questionValues[key];
        }
    }

    const [answerHash, setAnswerHash] = useState(
        savedAnswer || questionIDDefaultValueHash
    );

    const handleOnChange = (key, value) => {
        const newAnswerHash = {
            ...answerHash,
            [key]: value,
        };

        setAnswerHash(newAnswerHash);

        if (onChange) {
            onChange(newAnswerHash);
        }
    };

    const [addressLine1Error, setAddressLine1Error] = useState(null);

    useEffect(() => {
        if (answerHash?.ADDRESS_USA_MILITARY_UNIT?.length > 35) {
            setAddressLine1Error(
                <FormElementError>
                    {t(questionConfig.ADDRESS_LINE1.maxError)}
                </FormElementError>
            );

            setIsAddressFromEditable(true);
        } else {
            setAddressLine1Error(null);
        }
    }, [answerHash, setIsAddressFromEditable, t]);

    const [key, setKey] = useState(new Date().getTime());
    const onEditClicked = () => {
        setIsAddressFromEditable(true);
    };

    const onCancelClicked = () => {
        setAnswerHash(savedAnswer);
        setKey(new Date().getTime());

        if (answerHash?.ADDRESS_USA_MILITARY_UNIT?.length > 35) {
            setIsAddressFromEditable(true);
        } else {
            setIsAddressFromEditable(false);
        }
    };

    const onSaveClicked = () => {
        document
            .getElementById('MOBILE_PHONE_INTERNATIONAL')
            .removeAttribute('required');

        if (formRef.current.isValid()) {
            setSavedAddress(answerHash);

            if (answerHash?.ADDRESS_USA_MILITARY_UNIT?.length > 35) {
                setIsAddressFromEditable(true);
            } else {
                setIsAddressFromEditable(false);
            }
            setDisableCancelButton(!savedAnswer);
        } else {
            formRef.current.animateToInvalid();
        }

        document
            .getElementById('MOBILE_PHONE_INTERNATIONAL')
            .setAttribute('required', '');
    };

    return (
        <>
            {isOneApp && (
                <div>
                    <div className={css.oneAppHelperText}>
                        {t(
                            questionConfig.ADDRESS_USA_STANDARD.oneApp
                                .helperText
                        )}
                    </div>
                    {isDiabetes && (
                        <div className={css.oneAppImageContainer}>
                            <Image
                                cloudinaryImageId="registration/components/questions/address/BG300-oneapp_tpaqnc"
                                width={64}
                                height={64}
                                alt="BG300 device"
                                showLoader={false}
                            />
                            {t(
                                questionConfig.ADDRESS_USA_STANDARD.oneApp
                                    .BG300Label
                            )}
                        </div>
                    )}
                    {isComprehensiveCarePrograms && (
                        <>
                            <div className={css.oneAppImageContainer}>
                                <Image
                                    cloudinaryImageId="registration/components/questions/address/WM1500-oneapp_jodh78"
                                    width={64}
                                    height={64}
                                    alt="WM1500 device"
                                    showLoader={false}
                                />
                                {t(
                                    questionConfig.ADDRESS_USA_STANDARD.oneApp
                                        .WM1500Label
                                )}
                            </div>
                            <div className={css.oneAppImageContainer}>
                                <Image
                                    cloudinaryImageId="registration/components/questions/address/HT945_oneapp_yxj8av"
                                    width={64}
                                    height={64}
                                    alt="HT945 device"
                                    showLoader={false}
                                />
                                {t(
                                    questionConfig.ADDRESS_USA_STANDARD.oneApp
                                        .HT900Label
                                )}
                            </div>
                        </>
                    )}
                    {(isHypertension ||
                        isChronicKidneyDisease ||
                        isHeartFailure) && (
                        <div className={css.oneAppImageContainer}>
                            <Image
                                cloudinaryImageId="registration/components/questions/address/HT900-oneapp_k8wa3p"
                                width={64}
                                height={64}
                                alt="HT900 device"
                                showLoader={false}
                            />
                            {t(
                                questionConfig.ADDRESS_USA_STANDARD.oneApp
                                    .HT900Label
                            )}
                        </div>
                    )}
                    {(isWeightManagement ||
                        isPreDiabetes ||
                        isChronicKidneyDisease ||
                        isHeartFailure) && (
                        <div className={css.oneAppImageContainer}>
                            <Image
                                cloudinaryImageId="registration/components/questions/address/WM1500-oneapp_jodh78"
                                width={64}
                                height={64}
                                alt="WM1500 device"
                                showLoader={false}
                            />
                            {t(
                                questionConfig.ADDRESS_USA_STANDARD.oneApp
                                    .WM1500Label
                            )}
                        </div>
                    )}
                    {!isAddressFromEditable && (
                        <div className={css.addressCardContainer}>
                            <div className={css.title}>
                                <div>
                                    {t(
                                        questionConfig.ADDRESS_USA_STANDARD
                                            .oneApp.shippingCardTitle
                                    )}
                                </div>
                                <Button onClick={onEditClicked}>
                                    {t(
                                        questionConfig.ADDRESS_USA_STANDARD
                                            .oneApp.shippingCardEditButton
                                    )}
                                </Button>
                            </div>
                            <div>
                                {firstName} {lastName}
                            </div>
                            <div>{savedAnswer.ADDRESS_USA_MILITARY_UNIT}</div>
                            <div>
                                {savedAnswer.ADDRESS_USA_MILITARY_ADDRESS_TYPE}
                            </div>
                            <span>{savedAnswer.CITY}, </span>
                            <span>{savedAnswer.STATE} </span>
                            <span>{savedAnswer.ZIP}</span>
                        </div>
                    )}
                </div>
            )}
            <div
                className={cn(css.root, {
                    [css.oneAppRoot]: isOneApp,
                    [css.hideForm]: !isAddressFromEditable && isOneApp,
                })}
                id={id}
                key={key}
            >
                {isOneApp && (
                    <>
                        <div className={css.title}>
                            {t(
                                questionConfig.ADDRESS_USA_STANDARD.oneApp
                                    .shippingFormTitle
                            )}
                        </div>
                        <div className={css.helperText}>
                            {t(
                                questionConfig.ADDRESS_USA_COMBINED.oneApp
                                    .shippingFormHelperText
                            )}
                        </div>
                        <div className={css.requiredLabel}>
                            {t(
                                questionConfig.ADDRESS_USA_STANDARD.oneApp
                                    .requiredLabel
                            )}
                        </div>
                    </>
                )}

                {questionIDValueHash.ADDRESS_USA_MILITARY_UNIT && (
                    <TextInput
                        type={questionConfig.ADDRESS_USA_MILITARY_UNIT.type}
                        id={questionConfig.ADDRESS_USA_MILITARY_UNIT.id}
                        name={`${name}_-_${questionIDValueHash.ADDRESS_USA_MILITARY_UNIT.questionId.id}`}
                        defaultValue={answerHash.ADDRESS_USA_MILITARY_UNIT}
                        pattern={
                            questionConfig.ADDRESS_USA_MILITARY_UNIT.pattern
                        }
                        label={
                            <Label
                                i18nRequiredVisualLabel={isOneApp ? '*' : ' '}
                            >
                                {t(
                                    questionConfig.ADDRESS_USA_MILITARY_UNIT
                                        .label
                                )}
                            </Label>
                        }
                        placeholder={t(
                            questionConfig.ADDRESS_USA_MILITARY_UNIT.placeholder
                        )}
                        autoComplete={
                            questionConfig.ADDRESS_USA_MILITARY_UNIT
                                .autocomplete
                        }
                        maxLength={35}
                        error={
                            <FormElementError>
                                {t(
                                    questionConfig.ADDRESS_USA_MILITARY_UNIT
                                        .error
                                )}
                            </FormElementError>
                        }
                        customValidationError={
                            addressLine1Error || customValidationError
                        }
                        onChange={evt => {
                            handleOnChange(
                                'ADDRESS_USA_MILITARY_UNIT',
                                evt.target.value
                            );
                        }}
                        required={
                            questionIDValueHash.ADDRESS_USA_MILITARY_UNIT
                                .required
                        }
                    />
                )}
                {questionIDValueHash.ADDRESS_USA_MILITARY_ADDRESS_TYPE && (
                    <Select
                        name={`${name}_-_${questionIDValueHash.ADDRESS_USA_MILITARY_ADDRESS_TYPE.questionId.id}`}
                        id={questionConfig.ADDRESS_USA_MILITARY_ADDRESS_TYPE.id}
                        defaultValue={
                            answerHash.ADDRESS_USA_MILITARY_ADDRESS_TYPE
                        }
                        label={
                            <Label
                                i18nRequiredVisualLabel={isOneApp ? '*' : ' '}
                            >
                                {t(
                                    questionConfig
                                        .ADDRESS_USA_MILITARY_ADDRESS_TYPE.label
                                )}
                            </Label>
                        }
                        placeholder={t(
                            questionConfig.ADDRESS_USA_MILITARY_ADDRESS_TYPE
                                .placeholder
                        )}
                        items={[
                            {label: 'APO', value: 'APO'},
                            {label: 'FPO', value: 'FPO'},
                        ]}
                        autoComplete={
                            questionConfig.ADDRESS_USA_MILITARY_ADDRESS_TYPE
                                .autocomplete
                        }
                        error={
                            <FormElementError>
                                {t(
                                    questionConfig
                                        .ADDRESS_USA_MILITARY_ADDRESS_TYPE.error
                                )}
                            </FormElementError>
                        }
                        onChange={evt => {
                            handleOnChange(
                                'ADDRESS_USA_MILITARY_ADDRESS_TYPE',
                                evt?.value
                            );
                        }}
                        required={
                            questionIDValueHash
                                .ADDRESS_USA_MILITARY_ADDRESS_TYPE.required
                        }
                    />
                )}
                <div className={css.stateZip}>
                    {questionIDValueHash.ADDRESS_USA_MILITARY_STATE && (
                        <StateSelect
                            classNameRoot={css.state}
                            name={`${name}_-_${questionIDValueHash.ADDRESS_USA_MILITARY_STATE.questionId.id}`}
                            id={questionConfig.ADDRESS_USA_MILITARY_STATE.id}
                            defaultValue={
                                answerHash.STATE ||
                                answerHash.ADDRESS_USA_MILITARY_STATE
                            }
                            label={
                                <Label
                                    i18nRequiredVisualLabel={
                                        isOneApp ? '*' : ' '
                                    }
                                >
                                    {t(
                                        questionConfig
                                            .ADDRESS_USA_MILITARY_STATE.label
                                    )}
                                </Label>
                            }
                            placeholder={t(
                                questionConfig.ADDRESS_USA_MILITARY_STATE
                                    .placeholder
                            )}
                            autoComplete={
                                questionConfig.ADDRESS_USA_MILITARY_STATE
                                    .autocomplete
                            }
                            error={
                                <FormElementError>
                                    {t(
                                        questionConfig
                                            .ADDRESS_USA_MILITARY_STATE.error
                                    )}
                                </FormElementError>
                            }
                            onChange={evt => {
                                handleOnChange('STATE', evt?.value);
                            }}
                            required={
                                questionIDValueHash.ADDRESS_USA_MILITARY_STATE
                                    .required
                            }
                            includeMilitary={includeMilitary}
                            includeUSTerritories={includeUSTerritories}
                        />
                    )}
                    {questionIDValueHash.ZIP && (
                        <ZipInput
                            classNameRoot={css.zip}
                            id={questionConfig.ZIP.id}
                            name={`${name}_-_${questionIDValueHash.ZIP.questionId.id}`}
                            defaultValue={answerHash.ZIP}
                            helpNode=""
                            label={
                                <Label
                                    i18nRequiredVisualLabel={
                                        isOneApp ? '*' : ' '
                                    }
                                >
                                    {t(questionConfig.ZIP.label1)}
                                </Label>
                            }
                            error={
                                <FormElementError>
                                    {t(questionConfig.ZIP.error)}
                                </FormElementError>
                            }
                            customValidationError={customValidationError}
                            onChange={evt => {
                                handleOnChange('ZIP', evt?.target?.value);
                            }}
                            autoComplete={questionConfig.ZIP.autocomplete}
                            required={questionIDValueHash.ZIP.required}
                            usePlus4={false}
                        />
                    )}
                </div>
                {isOneApp && (
                    <div className={css.buttonContainer}>
                        <Button
                            className={css.cancelButton}
                            onClick={onCancelClicked}
                            disabled={disableCancelButton}
                        >
                            {t(
                                questionConfig.ADDRESS_USA_STANDARD.oneApp
                                    .shippingCardCancelButton
                            )}
                        </Button>
                        <Button
                            onClick={onSaveClicked}
                            size="small"
                            variant="primary"
                        >
                            {t(
                                questionConfig.ADDRESS_USA_STANDARD.oneApp
                                    .shippingCardSaveButton
                            )}
                        </Button>
                    </div>
                )}
            </div>
        </>
    );
};

AddressMilitary.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    question: PropTypes.object.isRequired,
    includeMilitary: PropTypes.bool,
    includeUSTerritories: PropTypes.bool,
    questionValues: PropTypes.object,
    /** A custom validation error (typically a FormElementError component) to display and override any other possible error passed when this component is part of a form and is invalid. */
    customValidationError: PropTypes.element,
    /**
     * Function to execute when the input changes.
     *
     * @param {hash} address - A hash with the question.
     */
    onChange: PropTypes.func,
    context: PropTypes.object.isRequired,
    isAddressFromEditable: PropTypes.bool,
    setIsAddressFromEditable: PropTypes.func,
    formRef: PropTypes.object,
};

export default AddressMilitary;
